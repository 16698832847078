import 'leaflet/dist/leaflet.css';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { onNewSiteSubmitted } from '../../../../state/site';

import { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import SiteForm from '../components/SiteForm';

const Add = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newSiteId, setNewSiteId] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const client = useSelector((state) => state.firestore.data.client);

  const showDialog = (id) => {
    setNewSiteId(id);
    setDialogOpen(true);
  };

  const onAgreeGoToSite = () => {
    history.push(location.pathname.replace('add', newSiteId));
  };

  const onDeclineGoToSite = () => {
    history.push(`/${client.clientId}`);
  };

  const onSiteSubmit = async (site) => {
    const result = await dispatch(onNewSiteSubmitted({ site }));
    showDialog(result.id);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SiteForm onSiteSubmit={onSiteSubmit} />
      </Grid>
      <Dialog
        open={dialogOpen}
        onClose={onDeclineGoToSite}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">New Site Created</DialogTitle>
        <DialogContent>
          <Typography>
            Before you can add any surveys for this site you need to add at
            least one building to it.
          </Typography>
          <br />
          <Typography>Would you like to do this now?</Typography>
        </DialogContent>
        {client && (
          <DialogActions>
            <Button onClick={onDeclineGoToSite} color="primary">
              No
            </Button>
            <Button
              onClick={onAgreeGoToSite}
              color="primary"
              autoFocus
              component={HashLink}
              to={`/${client.clientId}/sites/${newSiteId}#buildings`}
            >
              Yes
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </Grid>
  );
};

export default Add;
