import { CreatePhotoScheduleReportData } from '../types';
import {
  addThumbParamToUrl,
  getSafeTextFromText,
  removeParamsFromUrl,
} from '../helpers';

interface PhotoSchedule {
  data: CreatePhotoScheduleReportData;
}

export default function PhotoSchedule({ data }: PhotoSchedule) {
  const assets = data.assetData;

  const { field1, field2, field3, field4, field5 } = data;

  const customFieldsSafe = [field1, field2, field3, field4, field5]
    .filter((f) => f)
    .map((field) => (field ? getSafeTextFromText(field) : ''));

  return (
    <>
      <div className="page-break fill-page flex-justify-center flex-align-center">
        <h1 className="page-heading  toc-child-element">
          2.0 Photographic Schedule
        </h1>
      </div>
      <div className="page-break fill-page">
        <div className="page-content">
          {assets
            .filter(
              (asset) => asset.Image_1 && asset.Image_1.startsWith('https'),
            )
            .map((asset, index: number) => {
              console.log({ asset });

              const isNewSurvey =
                index > 0 && asset.Survey_ID !== assets[index - 1]?.Survey_ID;
              //   const isNewFloor =
              //     index > 0 && asset.Floor_Name !== assets[index - 1]?.Floor_Name;
              const isNewRoom =
                index > 0 && asset.Room_ID !== assets[index - 1]?.Room_ID;
              const assetHierarchy = `${asset.Code_0} ${asset.Label_0} > ${asset.Code_1} ${asset.Label_1} > ${asset.Code_2} ${asset.Label_2}`;
              const a = `https://portal.asseticom.co.uk/${asset.Client_ID}/surveys/${asset.Survey_ID}/${asset.Room_ID}/${asset.ID}`;
              const floorLinkText = `${
                asset.Floor_Name ?? 'Unknown Floor Name'
              }`;
              const roomTitleText = `${floorLinkText} - ${asset.Room_Name}`;

              //   const uniqueFloorId = `${asset.Survey_ID}${asset.Floor_Name}`;
              const className =
                isNewSurvey || isNewRoom
                  ? 'asset-details-holder page-break'
                  : 'asset-details-holder';

              return (
                <div key={asset.ID} className={className}>
                  <div className="top-left-content">
                    <div className="site-and-building flex-column flex-justify-start flex-align-start">
                      <span>{`${asset.Site_Name} - ${asset.Building_Name}`}</span>
                      <span>{`${asset.Survey_Name} - ${roomTitleText}`}</span>
                    </div>
                  </div>

                  <div className="asset-details-titles-holder">
                    {index === 0 || isNewSurvey ? (
                      <p className="toc-heading-element page-heading">
                        {asset.Survey_Name}
                      </p>
                    ) : (
                      <></>
                    )}

                    {index === 0 || isNewRoom ? (
                      <p className="toc-child-element">{roomTitleText}</p>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="hierarchy-text-holder">
                    <span>{assetHierarchy}</span>
                  </div>
                  <div className="asset-details">
                    <div className="flex-row flex-align-center flex-wrap">
                      <p className="asset-name text-bold">{asset.Name}</p>
                    </div>
                    <div className="asset-details-text flex-row flex-fill flex-align-center flex-justify-start">
                      <div className="asset-details-text">
                        {customFieldsSafe
                          ? customFieldsSafe.map((field, index) => {
                              return (
                                <div
                                  key={field}
                                  className="flex-row flex-align-center flex-wrap"
                                >
                                  <p className="field-name">{field}</p>
                                  <p>{asset[field] ?? 'N/A'}</p>
                                </div>
                              );
                            })
                          : null}
                        <div className="flex-row flex-align-center">
                          <a className="view-asset-button" href={a}>
                            View on Asseticom
                          </a>
                        </div>
                      </div>
                      {asset.Image_1 ? (
                        <div className="asset-details-image-holder">
                          <a
                            href={removeParamsFromUrl(asset.Image_1 as string)}
                          >
                            <img
                              className="asset-details-image"
                              src={addThumbParamToUrl(asset.Image_1 as string)}
                            />
                          </a>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}
