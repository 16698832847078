import 'leaflet/dist/leaflet.css';

import { Box, CircularProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';

import { makeStyles } from '@mui/styles';
import { visuallyHidden } from '@mui/utils';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import PropTypes from 'prop-types';
import useDebounce from '../hooks/useDebounce';
import useFetch from '../hooks/useFetch';
import { getGeocoderUrl } from '../shared/geo-location/getGeoData';

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

const useStyles = makeStyles((theme) => ({
  mapHolder: {
    backgroundColor: theme.palette.light,
    marginTop: theme.spacing(2),
    width: '100%',
    height: '300px',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const MIN_POSTCODE_LENGTH = 4;
const SEARCH_TIMEOUT = 500;

const LocationMap = ({
  postCode,
  countryCode = 'GB',
  onLocationResult = () => null,
}) => {
  const classes = useStyles();
  const DEFAULT_MAP_ZOOM_LEVEL = 14;

  const [apiUrl, setApiUrl] = useState(null);
  const [latLng, setLatLng] = useState([0, 0]);
  const [map, setMap] = useState(null);

  const { data } = useFetch(apiUrl);

  useDebounce(
    async () => {
      if (postCode && postCode.length >= MIN_POSTCODE_LENGTH) {
        setApiUrl(getGeocoderUrl({ postCode, countryCode }));
      }
    },
    SEARCH_TIMEOUT,
    [postCode, countryCode],
  );

  useEffect(() => {
    if (map && data.postalCodes?.length) {
      const result = data.postalCodes[0];
      const { lat, lng } = result;
      const position = [lat, lng];
      setLatLng([lat, lng]);
      map.setView(position, DEFAULT_MAP_ZOOM_LEVEL);
      onLocationResult({ latitude: lat, longitude: lng });
    } else if (map) {
      setLatLng([0, 0]);
      map.setView([0, 0], DEFAULT_MAP_ZOOM_LEVEL);
      onLocationResult({ latitude: 0, longitude: 0 });
    }
  }, [data.postalCodes]);

  if (postCode && postCode.length >= MIN_POSTCODE_LENGTH) {
    return (
      <Box>
        <Typography sx={visuallyHidden} id="map-description">
          This map shows the location of post code {postCode}
        </Typography>
        <Box
          className={classes.mapHolder}
          aria-describedby="map-description"
          tabindex="0"
        >
          {apiUrl ? (
            <MapContainer
              whenCreated={setMap}
              center={latLng}
              zoom={DEFAULT_MAP_ZOOM_LEVEL}
              scrollWheelZoom={true}
              style={{ height: '100%', width: '100%' }}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://tile.thunderforest.com/atlas/{z}/{x}/{y}.png?apikey=e90d6bdb4bf6435db613f186dbac1af8"
              />
              <Marker position={latLng} />
            </MapContainer>
          ) : (
            <CircularProgress color="primary" />
          )}
        </Box>
      </Box>
    );
  }
  return null;
};

LocationMap.defaultProps = {
  postCode: '',
  onLocationResult: null,
};

LocationMap.propTypes = {
  postCode: PropTypes.string,
  onLocationResult: PropTypes.func,
};

export default LocationMap;
