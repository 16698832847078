import {
  getBarcodeFormFieldFromSurvey,
  getRandomID,
  handlePlural,
  statuses,
  uploadImageIfNeeded,
} from '../shared/utilities';
import { setLoading, setSnackMessage } from './ui';

import { PATHS } from '../config/constants';
import { getLastUpdated } from '../shared/logTools';
import { getSnapshotByObject } from 'redux-firestore';

function getAssetRef(firestore, state, asset) {
  const { roomId, surveyId, clientId } = state.params.ids;
  return asset.id
    ? getSnapshotByObject(asset).ref
    : firestore
        .collection('clients')
        .doc(clientId)
        .collection('surveys')
        .doc(surveyId)
        .collection('rooms')
        .doc(roomId)
        .collection('assets')
        .doc();
}

export function getEmptyPartial() {
  return {
    costPercentage: 0,
    created: 0,
    deleted: 0,
    description: '',
    id: '',
    images: [],
    nextRenewal: 0,
    reccurs: false,
    reccursPeriod: 0,
    uom: '',
  };
}

export const NEW_PARTIAL_ID = '0';

export const onAssetSubmit =
  ({ values, attributes, images, asset, updated }) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      const firestore = getFirestore();
      const { clientId, siteId, surveyId, roomId } = asset;

      const assetRef = getAssetRef(firestore, getState(), asset);
      const { uid } = getState().firebase.auth;
      const room = getState().firestore.data.room;

      const label = attributes.label3 || '';
      const code = attributes.code3 || '';

      if (updated || !asset.id) {
        dispatch(
          setLoading({
            loadingMessage: 'Saving asset, please wait...',
          }),
        );
        try {
          const { nameFirst, nameLast } = getState().firebase.profile;
          const newEdit = {
            date: Date.now(),
            name: `${nameFirst} ${nameLast}`,
          };
          const edits = asset.edits ? [...asset.edits, newEdit] : [newEdit];

          const assetUpdateObj = {
            ...asset,
            label,
            code,
            id: assetRef.id,
            floorName: room.floor,
            values,
            attributes,
            edits,
            ...getLastUpdated(),
            status: statuses.COMPLETED,
          };

          // if its a new asset then these need to be assigned, if it isnt then dont want to overwrite
          if (!asset.id) {
            assetUpdateObj.surveyorName = `${nameFirst} ${nameLast}`;
            assetUpdateObj.surveyorId = uid;
          }

          const barcodeField = getBarcodeFormFieldFromSurvey(
            getState().firestore.data.survey,
          );

          if (barcodeField) {
            assetUpdateObj.barcode = values[barcodeField.id];
          }

          const imagePromises = [];

          const bucketPath = PATHS.STORAGE.IMAGE_ASSET;
          const uploadPath = `/${clientId}/${siteId}/${surveyId}/${roomId}/${assetRef.id}`;

          for (let index = 0; index < images.length; index += 1) {
            const image = images[index];
            imagePromises.push(
              uploadImageIfNeeded({
                image,
                bucketPath,
                uploadPath,
              }),
            );
          }

          await Promise.all(imagePromises).then((results) => {
            assetUpdateObj.images = results;
          });

          const batch = firestore.batch();

          batch.set(assetRef, assetUpdateObj, { merge: true });

          if (!asset.id) {
            const assetIncrement = firestore.FieldValue.increment(1);

            const surveyRef = firestore
              .collection('clients')
              .doc(clientId)
              .collection('surveys')
              .doc(surveyId);

            const roomRef = surveyRef.collection('rooms').doc(roomId);
            const userRef = firestore.collection('users').doc(uid);

            batch.update(surveyRef, { assetsNumCreated: assetIncrement });
            batch.update(roomRef, { assetsNumCreated: assetIncrement });
            batch.update(userRef, { assetsNumCreated: assetIncrement });
          }

          await batch.commit();
        } finally {
          dispatch(setLoading(false));
        }
      }
      dispatch(setSnackMessage({ message: `${label || 'Asset'} saved` }));
      return values;
    } catch (error) {
      console.error(error);
      return { error };
    }
  };
export const onAssetTypeChange =
  ({ attributes, asset }) =>
  async (dispatch, getState, { getFirestore }) => {
    try {
      const assetRef = getAssetRef(getFirestore(), getState(), asset);
      const { nameFirst, nameLast } = getState().firebase.profile;
      const newEdit = { date: Date.now(), name: `${nameFirst} ${nameLast}` };
      const edits = asset.edits ? [...asset.edits, newEdit] : [newEdit];
      const assetUpdateObj = {
        attributes,
        edits,
        ...getLastUpdated(),
        label: attributes.label3,
        code: attributes.code3,
      };

      if (attributes.expectedLife < asset.values.Remaining_Life) {
        assetUpdateObj.values = {
          ...asset.values,
          Remaining_Life: attributes.expectedLife,
        };
      }

      await assetRef.set(assetUpdateObj, { merge: true });

      dispatch(setSnackMessage({ message: 'Asset type changed' }));
      return true;
    } catch (error) {
      console.error(error);
      return { error };
    }
  };

export const onPartialSubmit =
  ({ formData, asset, updated, partialId }) =>
  async (dispatch, getState, { getFirestore }) => {
    try {
      const firestore = getFirestore();
      const { clientId, siteId, surveyId, roomId } = asset;

      // if submitted but no updates made to data then avoid changing any data
      const { images, ...values } = formData;

      let updatedPartial;

      const assetRef = getAssetRef(getFirestore(), getState(), asset);

      const isNewPartial = !partialId || partialId === NEW_PARTIAL_ID;

      if (updated || isNewPartial) {
        dispatch(
          setLoading({
            loadingMessage: 'Saving partial renewal, please wait...',
          }),
        );
        const { nameFirst, nameLast } = getState().firebase.profile;
        const newEdit = { date: Date.now(), name: `${nameFirst} ${nameLast}` };
        const edits = asset.edits ? [...asset.edits, newEdit] : [newEdit];

        // if partialId is not passed in then its a new partial
        let id = partialId;

        if (partialId === NEW_PARTIAL_ID || !partialId) {
          id = getRandomID();
          updatedPartial = {
            ...getEmptyPartial(),
            ...values,
            created: Date.now(),
            id,
          };
        } else {
          updatedPartial = {
            ...asset.partials[id],
            ...values,
          };
        }

        const imagePromises = [];

        const bucketPath = PATHS.STORAGE.IMAGE_ASSET;
        const uploadPath = `/${clientId}/${siteId}/${surveyId}/${roomId}/${assetRef.id}/${id}`;

        for (let index = 0; index < images.length; index += 1) {
          const image = images[index];
          imagePromises.push(
            uploadImageIfNeeded({
              image,
              bucketPath,
              uploadPath,
            }),
          );
        }

        await Promise.all(imagePromises).then((results) => {
          updatedPartial.images = results;
        });

        const newPartials = {
          ...asset.partials,
          ...{ [id]: updatedPartial },
        };

        const assetUpdateObj = {
          partials: newPartials,
          edits,
          partialsNumCreated: isNewPartial
            ? firestore.FieldValue.increment(1)
            : firestore.FieldValue.increment(0),
          ...getLastUpdated(),
        };

        const batch = firestore.batch();

        if (isNewPartial) {
          const incrementByOne = firestore.FieldValue.increment(1);

          // store the aggregation counters
          const additionUpdate = {
            partialsNumCreated: incrementByOne,
          };

          const surveyRef = firestore
            .collection('clients')
            .doc(clientId)
            .collection('surveys')
            .doc(surveyId);

          const roomRef = surveyRef.collection('rooms').doc(roomId);

          batch.update(surveyRef, { ...additionUpdate, ...getLastUpdated() });
          batch.update(roomRef, { ...additionUpdate, ...getLastUpdated() });
        }

        batch.update(assetRef, assetUpdateObj);

        batch.commit();
        dispatch(setLoading(false));
      }
      dispatch(setSnackMessage({ message: 'Partial saved' }));
      return formData;
    } catch (error) {
      console.error(error);
      return { error };
    }
  };

export const onSelectedAssetDelete =
  ({ asset }) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      const firestore = getFirestore();
      const deletedDate = Date.now();
      const assetIncrement = firestore.FieldValue.increment(1);
      const partialsIncrement = firestore.FieldValue.increment(
        Object.keys(asset.partials).length,
      );
      // add the deleted prop to each partial
      const updatedPartials = {};
      Object.keys(asset.partials).forEach((partialId) => {
        updatedPartials[partialId] = {
          ...asset.partials[partialId],
          deleted: deletedDate,
        };
      });
      // store the aggregation counters
      const deletionUpdate = {
        assetsNumDeleted: assetIncrement,
        partialsNumDeleted: partialsIncrement,
      };
      const { assetId, roomId, surveyId, clientId } = getState().params.ids;

      const surveyRef = firestore
        .collection('clients')
        .doc(clientId)
        .collection('surveys')
        .doc(surveyId);

      const roomRef = surveyRef.collection('rooms').doc(roomId);
      const assetRef = roomRef.collection('assets').doc(assetId || asset.id);

      const batch = firestore.batch();

      batch.update(assetRef, {
        deleted: deletedDate,
        partials: updatedPartials,
        partialsNumDeleted: partialsIncrement,
        ...getLastUpdated(),
      });
      batch.update(surveyRef, { ...deletionUpdate, ...getLastUpdated() });
      batch.update(roomRef, { ...deletionUpdate, ...getLastUpdated() });
      await batch.commit();

      return dispatch(setSnackMessage({ message: `${asset.label} deleted` }));
    } catch (error) {
      console.error(error);
      return { error };
    }
  };

export const onSelectedPartialDelete =
  ({ asset, partialId }) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      const firestore = getFirestore();
      const deletedDate = Date.now();
      const { assetId, roomId, surveyId, clientId } = getState().params.ids;
      const partialsIncrement = firestore.FieldValue.increment(1);

      // add the deleted prop to the partial
      const updatedPartial = {
        ...asset.partials[partialId],
        deleted: deletedDate,
      };
      const updatedPartials = {
        ...asset.partials,
        [partialId]: updatedPartial,
      };

      // store the aggregation counters
      const deletionUpdate = {
        partialsNumDeleted: partialsIncrement,
      };

      const surveyRef = firestore
        .collection('clients')
        .doc(clientId)
        .collection('surveys')
        .doc(surveyId);

      const roomRef = surveyRef.collection('rooms').doc(roomId);
      const assetRef = roomRef.collection('assets').doc(assetId || asset.id);
      const batch = firestore.batch();

      batch.update(assetRef, {
        partials: updatedPartials,
        ...deletionUpdate,
        ...getLastUpdated(),
      });
      batch.update(surveyRef, { ...deletionUpdate, ...getLastUpdated() });
      batch.update(roomRef, { ...deletionUpdate, ...getLastUpdated() });
      await batch.commit();

      return dispatch(setSnackMessage({ message: `Partial renewal deleted` }));
    } catch (error) {
      console.error(error);
      return { error };
    }
  };

export const getAssetDeleteMessages = (asset) => {
  const messages = [`Are you sure you want to delete ${asset.label}?`];
  const numPartials = asset.partialsNumCreated - asset.partialsNumDeleted;
  if (numPartials) {
    messages.push(
      `This will also delete the ${handlePlural(
        numPartials,
        'partial renewals',
        true,
      )} asssociated with this asset`,
    );
  }
  return messages;
};
