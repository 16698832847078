import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { useFirestore } from 'react-redux-firebase';
import { colours } from '../config/theme';
import { useModal } from './Modal';

export default function AssetIdSearcher() {
  const firestore = useFirestore();
  const location = useLocation();
  const params = useParams();
  const history = useHistory();
  const { showModal } = useModal();
  const { control, reset, getValues } = useForm({
    defaultValues: { assetId: '' },
  });
  const assetIdWatcher = useWatch({ control, name: 'assetId' });

  const onClickSearchAssetById = async () => {
    const { assetId } = getValues();
    const queryResult = await firestore
      .collectionGroup('assets')
      .where('clientId', '==', params.clientId)
      .where('id', '==', assetId)
      .get();

    const withoutDeleted = queryResult.docs?.filter(
      (doc) => doc.data().deleted === 0,
    );

    if (queryResult?.empty || withoutDeleted?.length === 0) {
      return showModal({
        title: 'Asset Not Found!',
        buttons: {
          confirm: [{ text: 'Ok' }],
        },
        messages: [`No asset was found with ID ${assetId}`],
      });
    }
    const assetData = withoutDeleted[0].data();
    const { id } = assetData;
    const url = `/${assetData.clientId}/surveys/${assetData.surveyId}/${assetData.roomId}/${id}`;
    return history.push(url);
  };
  return (
    <FormControl variant="outlined" size="small">
      <Controller
        control={control}
        label="Search Asset by ID"
        name="assetId"
        id="assetId"
        rules={{
          required: false,
        }}
        render={({ field: { onChange, value } }) => (
          <>
            <InputLabel htmlFor="outlined-adornment-go-button">
              Search Asset by ID
            </InputLabel>
            <OutlinedInput
              onChange={onChange}
              sx={{ p: 0 }}
              id="outlined-adornment-go-button"
              type="text"
              endAdornment={
                <InputAdornment position="end">
                  <Button
                    disabled={!assetIdWatcher}
                    onClick={onClickSearchAssetById}
                    sx={{
                      color: colours.brand1,
                    }}
                  >
                    Go
                  </Button>
                </InputAdornment>
              }
              label="Search Asset by ID"
            />
          </>
        )}
      />
    </FormControl>
  );
}
