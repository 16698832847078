export const ASSET_IMAGE_HEIGHT = 150;
const SHORT_URL_PREFIXES = [
  'https://asseticom.co.uk/s/',
  'https://s.asseticom.co.uk/',
];

export function getSafeTextFromText(label: string) {
  return label.replace(/\s/g, '_').replace(/[^\w\d_]/g, '');
}

export function removeParamsFromUrl(url: string) {
  return url.split('?')[0] ?? url;
}

export function addThumbParamToUrl(url: string) {
  //if its not a short url, because the url has not been shortened for whatever reason - return it as is
  if (!SHORT_URL_PREFIXES.some((prefix) => url.startsWith(prefix))) {
    return url;
  }
  //ensure there arent already params present
  const withoutParams = removeParamsFromUrl(url);
  const imageUrl = `${withoutParams}?height=${ASSET_IMAGE_HEIGHT}`;
  return imageUrl;
}
