const GEONAMES_URL = 'https://secure.geonames.org/postalCodeSearchJSON';

export function getGeocoderUrl({
  postCode,
  countryCode,
}: {
  postCode: string;
  countryCode: string;
}) {
  const params = new URLSearchParams({
    postalcode: postCode,
    country: countryCode,
    maxRows: '1',
    username: 'asseticom',
  });
  return `${GEONAMES_URL}?${params}`;
}

export async function fetchGeocoderData({
  postCode,
  countryCode,
}: {
  postCode: string;
  countryCode: string;
}) {
  const response = await fetch(getGeocoderUrl({ postCode, countryCode }));
  const responseJson = await response.json();
  return responseJson.postalCodes?.[0] ?? { lat: 0, lng: 0 };
}
