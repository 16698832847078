export enum Statuses {
  UNASSIGNED = 'unassigned',
  ISSUED = 'issued',
  NOT_STARTED = 'notStarted',
  IN_PROGRESS = 'inProgress',
  COMPLETED = 'completed',
  NO_ACCESS = 'noAccess',
}

export enum QueueStatuses {
  NOT_STARTED = 'notStarted',
  UPLOADING = 'uploading',
  DOWNLOADING = 'downloading',
  IDLE = 'idle',
  COMPLETE = 'complete',
  ERROR = 'error',
}

export enum RetriesEnum {
  'NONE' = 0,
  'ONE' = 1,
  'TWO' = 2,
  'THREE' = 3,
}
