import { useEffect, useReducer, useRef } from 'react';

export default (url) => {
  const cache = useRef({});

  const initialState = {
    status: 'IDLE',
    error: null,
    data: [],
  };

  const [state, dispatch] = useReducer((prevState, action) => {
    switch (action.type) {
      case 'IN_PROGRESS':
        return { ...initialState, status: 'IN_PROGRESS' };
      case 'SUCCESS':
        return { ...initialState, status: 'SUCCESS', data: action.payload };
      case 'ERROR':
        return { ...initialState, status: 'ERROR', error: action.payload };
      default:
        return prevState;
    }
  }, initialState);

  useEffect(() => {
    let cancelRequest = false;
    if (url) {
      const fetchData = async () => {
        dispatch({ type: 'IN_PROGRESS' });
        if (cache.current[url]) {
          const data = cache.current[url];
          dispatch({ type: 'SUCCESS', payload: data });
        } else {
          try {
            const response = await fetch(url);
            const data = await response.json();
            cache.current[url] = data;
            if (cancelRequest) return;
            dispatch({ type: 'SUCCESS', payload: data });
          } catch (error) {
            if (cancelRequest) return;
            dispatch({ type: 'ERROR', payload: error.message });
          }
        }
      };

      fetchData();
    }
    return () => {
      cancelRequest = true;
    };
  }, [url]);

  return state;
};
