import { isValidPostalCode } from 'postal-code-validator';
import XLSX from 'xlsx-js-style';
import z, { SafeParseReturnType, ZodIssue } from 'zod';

import {
  isValidCountryCode,
  parseXLSXSheet,
} from '../../../../shared/utilities';

const SHEETS_SCHEMA = z.array(z.literal('Sites and Buildings'));

const HEADERS_SCHEMA = z.tuple([
  z.literal('Site Name'),
  z.literal('Site Address 1'),
  z.literal('Site Address 2'),
  z.literal('Site Town/City'),
  z.literal('Site County'),
  z.literal('Site Region'),
  z.literal('Site Post Code'),
  z.literal('Site Country Code'),
  z.literal('Building Name'),
  z.literal('Building Address 1'),
  z.literal('Building Address 2'),
  z.literal('Building Town/City'),
  z.literal('Building County'),
  z.literal('Building Region'),
  z.literal('Building Post Code'),
  z.literal('Building Country Code'),
]);

function checkCountryCode(value: string | undefined, ctx: z.RefinementCtx) {
  if (!value) {
    return;
  }
  const valid = isValidCountryCode(value);

  if (!valid) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `Country code "${value}" not valid`,
    });
  }
}

function checkPostCode(value: string | undefined, ctx: z.RefinementCtx) {
  if (!value) {
    return;
  }
  const valid = isValidPostalCode(value);

  if (!valid) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `Post code "${value}" not valid`,
    });
  }
}

const ROWS_SCHEMA = z
  .object({
    'Site Name': z.string().min(1).trim(),
    'Site Address 1': z.string().min(1).trim(),
    'Site Address 2': z.string().min(1).trim().optional(),
    'Site Town/City': z.string().min(1).trim(),
    'Site County': z.string().min(1).trim(),
    'Site Region': z.string().min(1).trim(),
    'Site Post Code': z.string().min(1).trim().superRefine(checkPostCode),
    'Site Country Code': z.string().min(1).trim().superRefine(checkCountryCode),
    'Building Name': z.string().min(1).trim(),
    'Building Address 1': z.string().min(1).trim().optional(),
    'Building Address 2': z.string().min(1).trim().optional(),
    'Building Town/City': z.string().min(1).trim().optional(),
    'Building County': z.string().min(1).trim().optional(),
    'Building Region': z.string().min(1).trim().optional(),
    'Building Post Code': z
      .string()
      .min(1)
      .trim()
      .optional()
      .superRefine(checkPostCode),
    'Building Country Code': z
      .string()
      .min(1)
      .trim()
      .optional()
      .superRefine(checkCountryCode),
  })
  .refine(
    (val) => {
      // If any building address field is defined, check if all required building fields are defined
      const hasBuildingField =
        val['Building Address 1'] !== undefined ||
        val['Building Address 2'] !== undefined ||
        val['Building Town/City'] !== undefined ||
        val['Building County'] !== undefined ||
        val['Building Region'] !== undefined ||
        val['Building Post Code'] !== undefined ||
        val['Building Country Code'] !== undefined;

      if (hasBuildingField) {
        // If any building field is defined, these ones MUST be defined
        return (
          val['Building Address 1'] !== undefined &&
          val['Building Town/City'] !== undefined &&
          val['Building County'] !== undefined &&
          val['Building Region'] !== undefined &&
          val['Building Post Code'] !== undefined &&
          val['Building Country Code'] !== undefined
        );
      }

      return true;
    },
    {
      message:
        'To use a different building address to site address, all building address fields must be defined.',
      path: ['Building Address'],
    },
  );

type ValidationRow = z.infer<typeof ROWS_SCHEMA>;

// Create a type for the validation responses
export type SitesAndBuildingsWorkbookValidationResponse = {
  'Sheet Names': ValidationResponse<string[]>;
  Rows: ValidationResponse<ValidationRow>;
};

type ErrorMessageBuilder = (issue: ZodIssue, lineNumber?: number) => string;

const defaultMessageBuilder: ErrorMessageBuilder = (
  issue: ZodIssue,
  lineNumber?: number,
): string => {
  const rowInfo = lineNumber !== undefined ? ` [ Row: ${lineNumber} ]` : '';
  const pathInfo =
    issue.path.length > 0 ? ` [ Column: ${issue.path.join('.')} ]` : '';

  switch (issue.code) {
    case 'invalid_literal':
    case 'invalid_type':
      return `Expected ${issue.expected}, received ${issue.received} ${pathInfo}${rowInfo}`;
    case 'invalid_enum_value':
      return `Expected one of "${issue.options.join(' | ')}, received ${
        issue.received
      }"${pathInfo}${rowInfo}`;
    case 'custom':
      return `${issue.message}${pathInfo}${rowInfo}`;
    default:
      return `${issue.message}${pathInfo}${rowInfo}`;
  }
};

export type ValidationResponse<T = unknown> = {
  success: boolean;
  identifier: string;
  errors: string[];
  data: T[];
};

function handleValidationResponse<T>({
  response,
  identifier,
  rowNumber: lineNumber,
}: {
  response: SafeParseReturnType<unknown, T>;
  identifier: string;
  rowNumber?: number | undefined;
}): ValidationResponse<T> {
  if (response.success) {
    return {
      success: true,
      identifier,
      data: [response.data],
      errors: [],
    };
  }

  return {
    success: false,
    identifier,
    errors: response.error.issues.map((issue) =>
      defaultMessageBuilder(issue, lineNumber),
    ),
    data: [],
  };
}

export function validateWorkbook(
  workbook: XLSX.WorkBook,
): SitesAndBuildingsWorkbookValidationResponse {
  const responses = {} as SitesAndBuildingsWorkbookValidationResponse;

  function sortResponse<T>(response: ValidationResponse<T>) {
    console.log('response', response);
    const existing = responses[response.identifier] ?? {
      identifier: response.identifier,
      success: true,
      data: [],
      errors: [],
    };

    responses[response.identifier] = {
      identifier: response.identifier,
      success: existing.success && response.success,
      data: [...existing.data, ...response.data],
      errors: [...existing.errors, ...response.errors],
    };

    if (response.errors.length) {
      throw new Error('Validation Error');
    }
  }

  try {
    sortResponse(
      handleValidationResponse({
        response: SHEETS_SCHEMA.safeParse(workbook.SheetNames),
        identifier: 'Sheet Names',
      }),
    );

    const rowsSheet = parseXLSXSheet({
      sheet: workbook.Sheets['Sites and Buildings'],
    });

    sortResponse(
      handleValidationResponse({
        response: HEADERS_SCHEMA.safeParse(rowsSheet.headers),
        identifier: 'Column Names',
        rowNumber: 1,
      }),
    );

    rowsSheet.rows.forEach((row, index) => {
      sortResponse(
        handleValidationResponse({
          response: ROWS_SCHEMA.safeParse(row),
          identifier: 'Rows',
          rowNumber: index + 2,
        }),
      );
    });

    return responses;
  } catch (e) {
    return responses;
  }
}
