import { Box } from '@mui/material';

import ComponentGuard from '../../../../components/ComponentGuard';
import { ALL_ROLES } from '../../../../config/roles';
import BulkUploader from '../components/BulkUploader';

const AddBulk = () => {
  return (
    <Box>
      <ComponentGuard auth={ALL_ROLES.ASSETICOM_ADMIN}>
        <BulkUploader />
      </ComponentGuard>
    </Box>
  );
};

export default AddBulk;
