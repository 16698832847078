import { z } from 'zod';
import { removeNewLineCharacters } from '../utilities';

export enum COLUMN_NAMES_MAP {
  ROOM_NAME = 'Room Name',
  ROOM_AREA_WALL = 'Room Area (wall)',
  ROOM_AREA_FLOOR = 'Room Area (floor)',
  FLOOR = 'Floor',
  CODE_0 = 'Code 0',
  LABEL_0 = 'Label 0',
  CODE_1 = 'Code 1',
  LABEL_1 = 'Label 1',
  CODE_2 = 'Code 2',
  LABEL_2 = 'Label 2',
  CODE_3 = 'Code 3',
  LABEL_3 = 'Label 3',
  UOM = 'Unit of Measure',
  COST_PER_UNIT = 'Cost Per Unit',
  UPLIFT = 'Uplift Multiplier',
  EXPECTED_LIFE = 'Expected Life',
  REMAINING_LIFE = 'Remaining Life',
  QUANTITY = 'Quantity',
  DESCRIPTION = 'Description',
  NOTES = 'Notes',
  ANNUAL_ENERGY_CONSUMPTION = 'Annual Energy Consumption (kWh)',
  ANNUAL_CARBON_EMISSIONS = 'Annual Carbon Emissions (KgCo2e)',
  EMBODIED_CARBON_EMISSIONS = 'Embodied Carbon Emissions (KgCo2e)',
  MAINTENANCE_CODE = 'Maintenance Code',
  REF_1 = 'Ref 1',
  REF_2 = 'Ref 2',
  REF_3 = 'Ref 3',
  REF_4 = 'Ref 4',
  REF_5 = 'Ref 5',
}

// Annual Energy Consumption kWh, Annual Carbon Emissions KgCo2e, Carbon Emissions KgCo2e

export const getFormOptionsSchema = (formOptions) => {
  const schema = {};
  if (formOptions.unit) {
    schema[COLUMN_NAMES_MAP.UOM] = UOM_SCHEMA;
  }
  if (formOptions.cost) {
    schema[COLUMN_NAMES_MAP.COST_PER_UNIT] = COST_PER_UNIT_SCHEMA;
  }
  if (formOptions.cost && formOptions.uplift) {
    schema[COLUMN_NAMES_MAP.UPLIFT] = UPLIFT_SCHEMA;
  }
  return z.object(schema);
};

export const getFormFieldSchema = (formFields) => {
  const requiredFields = {};

  const fieldSchema = formFields.reduce((acc, ff) => {
    if (ff.disabled) {
      return { ...acc };
    }
    const options = ff.options?.map((o) => o.value) ?? [];
    let baseType;
    switch (ff.type) {
      case 'Decimal':
      case 'Number':
      case 'Slider':
      case 'Switch':
        baseType = z.number().nonnegative();
        break;
      case 'Barcode':
      case 'Text':
        baseType = z.coerce.string();
        break;
      case 'Select':
      case 'Radio':
        baseType = z
          .preprocess(
            // as it stands, select and radio output string values in the app
            // so value should be forced to String before validating against the enums
            String,
            z.string(),
          )
          .refine(
            (val) => {
              return options.find((option) => val === option);
            },
            (val) => ({
              message: `${val} does not match one of the accepted options: ${options.join(
                ', ',
              )}`,
            }),
          );

        break;
    }

    if (ff.base && ff.required) {
      requiredFields[ff.label] = true;
    } else {
      baseType = baseType.optional();
    }

    return { ...acc, [ff.label]: baseType };
  }, {});

  const remainingLifeField = formFields.find(
    (ff) => ff.label === COLUMN_NAMES_MAP.REMAINING_LIFE,
  );
  if (!remainingLifeField?.disabled) {
    const exLifeSchema = z.object({
      [COLUMN_NAMES_MAP.EXPECTED_LIFE]: EXPECTED_LIFE_SCHEMA.optional(),
    });
    return z.object(fieldSchema).merge(exLifeSchema);
  }
  return z.object(fieldSchema);
};

export const schemaAsArray = ({ schema }) => {
  return z.array(schema);
};

export const parseDataWithSchema = ({ data, schema }) => {
  return schema.safeParse(data);
};

export const NO_WHITESPACES_ERROR = 'Cannot contain spaces';
export const EMPTY_ERROR = 'Cannot by empty';

export const noWhiteSpaces = (val, ctx) => {
  if (val.indexOf(' ') !== -1) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: NO_WHITESPACES_ERROR,
    });
  }
  if (val === 'undefined') {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: EMPTY_ERROR,
    });
  }
};

export const zeroIfEmpty = (val: any) => {
  return val ?? 0;
};

export const LEVEL_CODE_SCHEMA = z.coerce
  .string()
  .superRefine(noWhiteSpaces)
  .transform(removeNewLineCharacters);

export const LEVEL_LABEL_SCHEMA = z.coerce
  .string()
  .trim()
  .transform(removeNewLineCharacters);

export const UOM_SCHEMA = z.string().trim().transform(removeNewLineCharacters);
export const UPLIFT_SCHEMA = z.number().min(0).max(1);
export const COST_PER_UNIT_SCHEMA = z.number().nonnegative();
export const EXPECTED_LIFE_SCHEMA = z.number().int().nonnegative();
export const SUSTAINABILITY_SCHEMA = z.number().nonnegative().nullish();
export const REF_SCHEMA = z.string().or(z.number()).nullish();
export const NOTES_SCHEMA = z.string().or(z.number()).nullish();
export const MAINTENANCE_CODE_SCHEMA = z.string().or(z.number()).nullish();

export const LEVELS_SCHEMA = z.object({
  [COLUMN_NAMES_MAP.CODE_0]: LEVEL_CODE_SCHEMA,
  [COLUMN_NAMES_MAP.LABEL_0]: LEVEL_LABEL_SCHEMA,
  [COLUMN_NAMES_MAP.CODE_1]: LEVEL_CODE_SCHEMA,
  [COLUMN_NAMES_MAP.LABEL_1]: LEVEL_LABEL_SCHEMA,
  [COLUMN_NAMES_MAP.CODE_2]: LEVEL_CODE_SCHEMA,
  [COLUMN_NAMES_MAP.LABEL_2]: LEVEL_LABEL_SCHEMA,
  [COLUMN_NAMES_MAP.CODE_3]: LEVEL_CODE_SCHEMA,
  [COLUMN_NAMES_MAP.LABEL_3]: LEVEL_LABEL_SCHEMA,
});

export const DEFAULT_IMPORT_COLUMNS_SCHEMA = z
  .object({
    [COLUMN_NAMES_MAP.ROOM_NAME]: z
      .string()
      .trim()
      .transform(removeNewLineCharacters),
    [COLUMN_NAMES_MAP.FLOOR]: z
      .string()
      .trim()
      .transform(removeNewLineCharacters),
    [COLUMN_NAMES_MAP.ROOM_AREA_WALL]: z.number().nonnegative().default(0),
    [COLUMN_NAMES_MAP.ROOM_AREA_FLOOR]: z.number().nonnegative().default(0),
  })
  .merge(LEVELS_SCHEMA)
  .required();

function unique(array) {
  const seen = new Set();
  return array.filter(function (item) {
    if (!seen.has(item)) {
      seen.add(item);
      return false;
    }
  });
}

export const ASSET_TAXONOMY_SCHEMA = schemaAsArray({
  schema: LEVELS_SCHEMA.merge(
    z.object({
      [COLUMN_NAMES_MAP.UOM]: UOM_SCHEMA,
      [COLUMN_NAMES_MAP.COST_PER_UNIT]:
        COST_PER_UNIT_SCHEMA.optional().transform(zeroIfEmpty),
      [COLUMN_NAMES_MAP.EXPECTED_LIFE]:
        EXPECTED_LIFE_SCHEMA.optional().transform(zeroIfEmpty),
      [COLUMN_NAMES_MAP.UPLIFT]:
        UPLIFT_SCHEMA.optional().transform(zeroIfEmpty),
      [COLUMN_NAMES_MAP.NOTES]: NOTES_SCHEMA.optional().transform(
        (t) => t ?? '',
      ),
      [COLUMN_NAMES_MAP.ANNUAL_CARBON_EMISSIONS]:
        SUSTAINABILITY_SCHEMA.optional(),
      [COLUMN_NAMES_MAP.EMBODIED_CARBON_EMISSIONS]:
        SUSTAINABILITY_SCHEMA.optional(),
      [COLUMN_NAMES_MAP.ANNUAL_ENERGY_CONSUMPTION]:
        SUSTAINABILITY_SCHEMA.optional(),
      [COLUMN_NAMES_MAP.MAINTENANCE_CODE]: MAINTENANCE_CODE_SCHEMA.optional(),
      [COLUMN_NAMES_MAP.REF_1]: REF_SCHEMA.optional(),
      [COLUMN_NAMES_MAP.REF_2]: REF_SCHEMA.optional(),
      [COLUMN_NAMES_MAP.REF_3]: REF_SCHEMA.optional(),
      [COLUMN_NAMES_MAP.REF_4]: REF_SCHEMA.optional(),
      [COLUMN_NAMES_MAP.REF_5]: REF_SCHEMA.optional(),
    }),
  ),
}).superRefine((val, ctx) => {
  const allCodeThrees = val.map((v) => v['Code 3']);

  allCodeThrees.forEach((code, index) => {
    const otherIndex = allCodeThrees.indexOf(code);
    if (otherIndex !== index) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Code ${code} is not unique, all codes in the Code 3 column must be unique`,
        path: [index, 'Code 3'],
      });
    }
  });

  const codeAndLabel = new Map();

  function checkForCodeAndLabelMismatch(
    val: [],
    levelNum: number,
    index: number,
  ) {
    const code = `${val[`Code ${levelNum}`]}`;
    const label = `${val[`Label ${levelNum}`]}`;

    const codeOfParentLevel =
      levelNum > 0 ? `${val[`Code ${levelNum - 1}`]}` : '';

    const labelOfParentLevel =
      levelNum > 0 ? `${val[`Label ${levelNum - 1}`]}` : '';

    const identifier = `${codeOfParentLevel}_${code}_${levelNum}`;
    const hasEntry = codeAndLabel.has(identifier);

    if (!hasEntry) {
      return codeAndLabel.set(identifier, {
        code,
        label,
        codeOfParentLevel,
        labelOfParentLevel,
      });
    }

    const existingEntry = codeAndLabel.get(identifier);

    if (!label) {
      return console.error("label doesn't exist", index, val);
    }

    if (
      existingEntry.codeOfParentLevel === codeOfParentLevel &&
      existingEntry.labelOfParentLevel === labelOfParentLevel &&
      existingEntry.code === code &&
      existingEntry.label !== label
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Row has the same parent "${codeOfParentLevel} - ${labelOfParentLevel}" and same Code ${levelNum} as its siblings, but a different Label ${levelNum}. Use a unqiue code or align the label with its siblings`,
        path: [index, `Label ${levelNum}`],
      });
    }
  }

  val.forEach((v, index) => {
    checkForCodeAndLabelMismatch(v, 1, index);
    checkForCodeAndLabelMismatch(v, 2, index);
    checkForCodeAndLabelMismatch(v, 3, index);
  });
});

export const ROOM_SCHEDULE_SCHEMA = z
  .object({
    Floor: z.string().trim().transform(removeNewLineCharacters),
    'Room Name': z.string().trim().transform(removeNewLineCharacters),
    'Area (Floor & Ceiling)': z
      .number()
      .nonnegative()
      .transform(removeNewLineCharacters)
      .optional(),
    'Area (Wall)': z
      .number()
      .nonnegative()
      .transform(removeNewLineCharacters)
      .optional(),
  })
  .required();

// don't allow fields to use any of these 'reserved' names becuase they are used for internal purposes and would cause issues when inserting into Big Query
// stored in lower case to allow for case insensitive matching

const DISALLOWED_FIELD_NAMES = [
  'timestamp',
  'last updated',
  'created',
  'deleted',
  'relationship',
  'parent id',
  'client id',
  'site id',
  'site name',
  'building id',
  'building name',
  'survey id',
  'survey name',
  'room id',
  'room name',
  'floor name',
  'id',
  'name',
  'surveyor id',
  'surveyor name',
  'image 1',
  'image 2',
  'image 3',
  'image 4',
  'image 5',
  'renewal cost',
  'renewal multiplier',
  'code 0',
  'code 1',
  'code 2',
  'code 3',
  'label 0',
  'label 1',
  'label 2',
  'label 3',
  'expected life',
  'uom',
  'uplift multiplier',
  'cost',
  'next renewal',
  'reccurs',
  'reccurs period',
];

export const FORM_FIELD_LABEL_SCHEMA = z
  .string()
  .regex(/[a-z0-9]/, 'Must contain at least one letter or number')
  .regex(/^[a-zA-Z0-9].*/, 'Must start with a letter or number')
  .min(1, { message: 'Cannot be empty' })
  .trim()
  .refine(
    (val) => {
      return !DISALLOWED_FIELD_NAMES.includes(val.toLowerCase());
    },
    (val) => ({
      message: `${val} cannot be used as a form field label. Please choose another`,
    }),
  );
