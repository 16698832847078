import { CreatePhotoScheduleReportData } from '../../../shared/types';
import FrontPage from './pages/FrontPage';
import InsidePage from './pages/InsidePage';
import { IntroductionPage } from './pages/IntroductionPage';
import PhotoSchedule from './pages/PhotoSchedulePages';
import STYLES from './style.css?raw';
import TOC from './pages/TOC';

export function PhotoSchedulePDFReport({
  data,
}: {
  data: CreatePhotoScheduleReportData;
}) {
  const cacheBusted = `https://firebasestorage.googleapis.com/v0/b/asseticom-assets/o/create-toc.js?alt=media&cachebust=${Date.now()}`;
  const polyFill = `https://firebasestorage.googleapis.com/v0/b/asseticom-internal-resources/o/pagedjs.polyfill.js?alt=media`;
  return (
    <>
      <head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>PDF Preview</title>
        <style>{STYLES}</style>
      </head>
      <body className="flex-justify-center" style={{ visibility: 'hidden' }}>
        <FrontPage data={data} />
        <InsidePage data={data} />
        <TOC data={data} />
        <IntroductionPage data={data} />
        <PhotoSchedule data={data} />
        <script src={polyFill}></script>
        <script src={cacheBusted}></script>
      </body>
    </>
  );
}
