import { Box } from '@mui/material';
import { parseXLSX, readURLAsBinary } from '../../../../../../shared/utilities';

import { useState } from 'react';
import FileUploaderCard from '../../../../../../components/FileUploaderCard';
import StepGuide from '../../../../../../components/StepGuide';

type UploadFileStepProps = {
  stepNumber: number;
  onFileAdded: ({ rows, headers, name }) => void;
  onClickedRemove: () => void;
};

export default function UploadFileStep({
  stepNumber,
  onFileAdded,
  onClickedRemove,
}: UploadFileStepProps) {
  const [fileName, setFileName] = useState('');

  const setFileData = async (file) => {
    const { name } = file;
    const data = await readURLAsBinary(file);

    setFileName(name);

    const assetImportParsed = await parseXLSX(data);

    onFileAdded({
      rows: assetImportParsed.rows,
      headers: assetImportParsed.headers,
      name,
    });
  };

  return (
    <Box mt={8}>
      <StepGuide
        stepNumber={stepNumber}
        text="Upload your completed template file."
      />

      <FileUploaderCard
        title="Asset Data Import"
        onChange={(file) => setFileData(file)}
        onRemove={onClickedRemove}
        fileName={fileName}
      />
    </Box>
  );
}
