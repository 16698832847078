import {
  Paper,
  Stack,
  Step,
  StepButton,
  StepContent,
  Stepper,
} from '@mui/material';
import { resetFormData, setFormData } from '../../../../state/survey-form';
import { useCallback, useEffect, useState } from 'react';

import AssetList from './AssetList';
import Confirm from './Confirm';
import Fields from './Fields';
import FloorPlans from './FloorPlans';
import Import from './Import';
import Overview from './Overview';
import RoomSchedule from './RoomSchedule';
import { useDispatch } from 'react-redux';

const STEPS = [
  'Overview',
  'Asset Taxonomy',
  'Form Fields',
  'Import',
  'Room Schedule',
  'Floorplans',
  'Confirm',
];

const Steps = ({ initialData, onSubmit }) => {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);

  const updateFormData = (data) => {
    dispatch(setFormData(data));
  };

  useEffect(() => {
    dispatch(resetFormData({}));
    if (initialData) {
      updateFormData(initialData);
    }
  }, [initialData]);

  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleSubmit = () => {
    onSubmit({});
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleLeave = (data) => {
    updateFormData(data);
  };

  // used for testing
  // useEffect(() => {
  //   setActiveStep(0);
  // }, []);

  const getStepContent = useCallback(({ index }) => {
    switch (index) {
      case 0:
        return <Overview onSubmit={handleNext} onLeave={handleLeave} />;
      case 1:
        return (
          <AssetList
            onBack={handleBack}
            onSubmit={handleNext}
            onLeave={handleLeave}
          />
        );
      case 2:
        return (
          <Fields
            onBack={handleBack}
            onSubmit={handleNext}
            onLeave={handleLeave}
          />
        );
      case 3:
        return (
          <Import
            onBack={handleBack}
            onSubmit={handleNext}
            onLeave={handleLeave}
          />
        );
      case 4:
        return (
          <RoomSchedule
            onBack={handleBack}
            onSubmit={handleNext}
            onLeave={handleLeave}
          />
        );
      case 5:
        return (
          <FloorPlans
            onBack={handleBack}
            onSubmit={handleNext}
            onLeave={handleLeave}
          />
        );

      case 6:
        return <Confirm onBack={handleBack} onSubmit={handleSubmit} />;
      default:
        return 'Unknown step';
    }
  }, []);

  const handleClickedStep = (index) => {
    if (index < activeStep + 1) {
      setActiveStep(index);
    }
  };

  return (
    <Paper id="main-content">
      <Stepper activeStep={activeStep} orientation="vertical" sx={{ p: 2 }}>
        {STEPS.map((label, index) => (
          <Step key={label}>
            <Stack direction="row" spacing={2} alignItems="center">
              <StepButton onClick={() => handleClickedStep(index)}>
                {label}
              </StepButton>
            </Stack>
            <StepContent>
              {getStepContent({
                index,
              })}
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Paper>
  );
};

export default Steps;
