import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { QueryParams, UrlParams } from '../../../../../types/urlParams';

import { useForm } from 'react-hook-form';
import AddOrEditRoomDialog from '../../../../../components/AddOrEditRoomDialog';
import AssetIdSearcher from '../../../../../components/AssetIdSearcher';
import EditFloorDialog from '../../../../../components/EditFloorDialog';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import { useModal } from '../../../../../components/Modal';
import Table from '../../../../../components/Table';
import TableActionButton from '../../../../../components/TableActionButton';
import { useAppDispatch } from '../../../../../config/store';
import { useAllRoomsForFloor } from '../../../../../hooks/useAllRooms';
import useQueryParams from '../../../../../hooks/useQueryParams';
import useQueryParamsUpdater from '../../../../../hooks/useQueryParamsUpdater';
import useSurvey from '../../../../../hooks/useSurvey';
import { EMPTY_ARRAY } from '../../../../../shared/helpers';
import { onSaveFloor } from '../../../../../state/floor';
import { setParamId } from '../../../../../state/params';
import { onSaveRoom } from '../../../../../state/room';
import { RoomProps } from '../../../../../types/room';
import { Statuses } from '../../../../../types/status';
import ViewFloorPlanDialog from '../../components/ViewFloorPlanDialog';
import { EMPTY_ROOM, TABLE_COLUMNS } from './constants';
import exportRoomSchedule from './exportRoomSchedule';

const Rooms = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { fp, floor } = useQueryParams<QueryParams>();
  const { updateQueryParams } = useQueryParamsUpdater();
  const { clientId } = useParams<UrlParams>();

  const prevFloorName = useRef<string>();
  const [showFloorPlan, setShowFloorPlan] = useState(fp || false);
  const [roomToAddOrEdit, setRoomToAddOrEdit] = useState<
    undefined | Partial<RoomProps>
  >(undefined);
  const [floorToEdit, setFloorToEdit] = useState<string>();
  const survey = useSurvey();
  const { showModal } = useModal();

  const floors = useMemo(() => {
    return survey?.roomSchedule?.floors || EMPTY_ARRAY;
  }, [survey]);

  const floorParamAsName = useMemo(() => {
    return floors[floor || 0];
  }, [floor, floors, survey]);

  const [floorName, setFloorName] = useState(floorParamAsName);

  const { reset } = useForm({
    defaultValues: { floor: floorParamAsName || null },
  });

  const allRoomsForFloor = useAllRoomsForFloor(
    floorParamAsName,
    (a, b) => b.lastUpdated?.time - a.lastUpdated?.time,
  );

  const floorPlan = useMemo(() => {
    return survey.floorPlans?.find((fp) => fp.floor === floorName);
  }, [survey, floorName]);

  useEffect(() => {
    if (floorName && floors.length) {
      updateQueryParams({
        floor: floors.findIndex((f) => f === floorName),
      });
      prevFloorName.current = floorName;
      dispatch(
        setParamId({
          id: 'floor',
          value: floorName,
        }),
      );
    }
  }, [floorName, floors]);

  useEffect(() => {
    if (!floorParamAsName || !floors) {
      return;
    }
    reset({ floor: floorParamAsName || floors[0] });
    setFloorName(floorParamAsName);
  }, [floors, floorParamAsName]);

  const rows = allRoomsForFloor.map((room: RoomProps) => {
    const {
      id,
      name,
      floor,
      status,
      assetsNumCreated,
      assetsNumDeleted,
      partialsNumCreated,
      partialsNumDeleted,
      assetsNumFlagged,
      areaFloor,
      areaWall,
      lastUpdated,
    } = room;
    const totalAssets = assetsNumCreated - assetsNumDeleted;

    return {
      id,
      name,
      floor,
      image: room?.images?.[0]?.uri,
      images: room?.images,
      lastUpdated,
      areaFloor: areaFloor.toFixed(2),
      areaWall: areaWall.toFixed(2),
      assets: totalAssets || 0,
      partials: partialsNumCreated - partialsNumDeleted || 0,
      flagged: assetsNumFlagged || 0,
      status: status || 'N/A',
    };
  });

  const onClickExportRoomSchedule = async () => {
    exportRoomSchedule({ clientId: clientId, survey });
  };

  const onToggleFloorPlan = () => {
    updateQueryParams({ fp: !showFloorPlan });
    setShowFloorPlan(!showFloorPlan);
  };

  const onClickedViewRoom = (roomData) => {
    history.push(`${location.pathname}/${roomData.id}`);
  };
  const onClickedEditRoom = (roomData) => {
    setRoomToAddOrEdit(roomData);
  };

  const actionButtons = useMemo(
    () =>
      function buttons({ row, closeMenu }) {
        const buttons: React.ReactNode[] = [];

        const rowData = row.original;

        buttons.push(
          <TableActionButton
            key="View"
            text="View"
            colour="green"
            onClick={() => {
              closeMenu();
              onClickedViewRoom(rowData);
            }}
          />,
          survey.status !== Statuses.IN_PROGRESS && (
            <TableActionButton
              key="Edit"
              text="Edit"
              colour="blue"
              onClick={() => {
                closeMenu();
                onClickedEditRoom(rowData);
              }}
            />
          ),
        );

        return (
          <Stack direction="column" p={1}>
            {buttons}
          </Stack>
        );
      },
    [],
  );

  const onUpdateRoom = (data) => {
    dispatch(onSaveRoom({ room: data }));
  };

  const onClickedEditFloor = () => {
    setFloorToEdit(floorName);
  };

  const onUpdateFloor = (newFloorName, prevFloorName) => {
    if (floors.find((f) => f === newFloorName)) {
      return showModal({
        title: 'Floor Already Exists!',
        messages: [`There is already a floor called ${newFloorName}`],
        buttons: {
          confirm: [{ text: 'Ok' }],
        },
      });
    }

    dispatch(onSaveFloor({ newFloorName, prevFloorName }));
  };

  if (!floorName) {
    return null;
  }

  return (
    <Paper sx={{ width: '100%' }} id="main-content">
      <ErrorBoundary>
        <AddOrEditRoomDialog
          onSubmit={onUpdateRoom}
          floors={floors}
          room={roomToAddOrEdit}
          onClose={() => setRoomToAddOrEdit(undefined)}
        />
        <EditFloorDialog
          floor={floorToEdit}
          onClose={() => setFloorToEdit(undefined)}
          onSubmit={onUpdateFloor}
        />
        {floorPlan?.url && (
          <ViewFloorPlanDialog
            open={showFloorPlan}
            floorPlan={floorPlan}
            roomsList={allRoomsForFloor}
            onClose={onToggleFloorPlan}
          />
        )}
        <Table
          key={floorName}
          rows={rows}
          columns={TABLE_COLUMNS}
          onClickRow={onClickedViewRoom}
          tableProps={{
            renderRowActionMenuItems: actionButtons,
            enableRowActions: true,
            positionActionsColumn: 'first',
            renderTopToolbarCustomActions: () => {
              return (
                <Stack
                  px={1}
                  mb={1}
                  direction="row"
                  justifyContent="space-between"
                  sx={{ width: '100%' }}
                >
                  <Typography color="secondary">{floorName}</Typography>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Box flex={1}>
                      <FormControl
                        variant="filled"
                        sx={{ minWidth: 150, flex: 1 }}
                        fullWidth
                      >
                        <Autocomplete
                          value={floorName}
                          size="small"
                          disableClearable
                          options={floors}
                          onChange={(_, value) => {
                            updateQueryParams({ page: 0 });
                            setFloorName(value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Floor"
                              variant="outlined"
                            />
                          )}
                        />
                      </FormControl>
                    </Box>
                    {survey.status !== Statuses.IN_PROGRESS ? (
                      <Button
                        variant="outlined"
                        onClick={onClickedEditFloor}
                        color="secondary"
                      >
                        Edit Floor
                      </Button>
                    ) : null}

                    {floorPlan?.url ? (
                      <Button
                        variant="outlined"
                        onClick={onToggleFloorPlan}
                        color="primary"
                      >
                        View Floor Plan
                      </Button>
                    ) : null}
                    <Button
                      variant="outlined"
                      onClick={onClickExportRoomSchedule}
                      color="secondary"
                    >
                      Export Full Room Schedule
                    </Button>
                    <AssetIdSearcher />
                    <Button
                      color="primary"
                      onClick={() => setRoomToAddOrEdit(EMPTY_ROOM)}
                      variant="contained"
                    >
                      Add a Room
                    </Button>
                  </Stack>
                </Stack>
              );
            },
          }}
        />
      </ErrorBoundary>
    </Paper>
  );
};

export default Rooms;
