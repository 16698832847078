import { Box } from '@mui/material';
import { Switch } from 'react-router-dom';
import RouteGuard from '../../../components/RouteGuard';
import { ROUTES_MAP } from '../../../config/routes';
import Add from './add';
import AddBulk from './add-bulk';
import Edit from './edit';
import View from './view';

const Sites = () => {
  return (
    <Box>
      <Switch>
        <RouteGuard {...ROUTES_MAP.SITES_ADD}>
          <Add />
        </RouteGuard>
        <RouteGuard {...ROUTES_MAP.SITES_ADD_BULK}>
          <AddBulk />
        </RouteGuard>
        <RouteGuard {...ROUTES_MAP.SITES_EDIT}>
          <Edit />
        </RouteGuard>
        <RouteGuard {...ROUTES_MAP.SITES}>
          <View />
        </RouteGuard>
      </Switch>
    </Box>
  );
};

export default Sites;
