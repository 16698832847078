import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

import { Container, Toolbar } from '@mui/material';
import { NAV_ROUTES_MAP, ROUTES_MAP } from '../../config/routes';
import { Redirect, Route, Switch } from 'react-router-dom';

import Admins from '../../routes/admins';
import ClientPortal from '../../routes/client-portal';
import Clients from '../../routes/clients';
import { DRAWER_WIDTH } from '../../config/constants';
import Dashboard from '../../routes/dashboard';
import ErrorBoundary from '../ErrorBoundary';
import InternalTools from '../../routes/internal-tools';
import QRCodeNotFound from '../../routes/qr-code-not-found';
import Reports from '../../routes/reports';
import RouteGuard from '../RouteGuard';
import User from '../../routes/user';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      maxWidth: `calc(100% - ${DRAWER_WIDTH}px)`,
    },
  },
}));

const MainContent = () => {
  const classes = useStyles();

  return (
    <Container
      maxWidth="xl"
      className={classes.container}
      component="main"
      sx={{ paddingBottom: 10 }}
    >
      <Toolbar />
      <ErrorBoundary
        showDialog
        message="There has been an error, you should reload the page to continue"
      >
        <Switch>
          <RouteGuard {...NAV_ROUTES_MAP.USER}>
            <User />
          </RouteGuard>
          <RouteGuard {...ROUTES_MAP.ROOT}>
            <Dashboard />
          </RouteGuard>
          <RouteGuard {...ROUTES_MAP.INTERNAL_TOOLS}>
            <InternalTools />
          </RouteGuard>
          <RouteGuard {...ROUTES_MAP.REPORTS}>
            <Reports />
          </RouteGuard>
          <RouteGuard {...ROUTES_MAP.ASSETICOM}>
            <Redirect to={ROUTES_MAP.ROOT.path} />
          </RouteGuard>
          <RouteGuard {...NAV_ROUTES_MAP.CLIENTS}>
            <Clients />
          </RouteGuard>
          <RouteGuard {...NAV_ROUTES_MAP.ADMINS}>
            <Admins />
          </RouteGuard>
          <Route path={ROUTES_MAP.QR_CODE_NOT_FOUND.path}>
            <QRCodeNotFound />
          </Route>
          <RouteGuard {...ROUTES_MAP.DASHBOARD}>
            <ClientPortal />
          </RouteGuard>
          <Route path="*">
            <Redirect to={ROUTES_MAP.NOT_FOUND.path} />
          </Route>
        </Switch>
      </ErrorBoundary>
    </Container>
  );
};

export default MainContent;
