import { Grid } from '@mui/material';

import ComponentGuard from '../../../../components/ComponentGuard';
import { ALL_ROLES } from '../../../../config/roles';
import BulkUploader from '../components/BulkUploader';

const AddBulk = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ComponentGuard auth={ALL_ROLES.ASSETICOM_ADMIN}>
          <BulkUploader />
        </ComponentGuard>
      </Grid>
    </Grid>
  );
};

export default AddBulk;
