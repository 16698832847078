import 'firebase/firestore';
import 'firebase/functions';
import XLSX from 'xlsx-js-style';

import { functions } from '../config/store';
import {
  autoSizeWorksheetWidth,
  getStyledExportHeaders,
} from '../shared/utilities';

export const exportUsersClientRoles = async () => {
  try {
    const result = await functions.httpsCallable('getUsersClientRoles')();

    console.log({ result });
    const dataRows: {
      name: string;
      email: string;
      clientId: string;
      role: string;
    }[] = result.data.rows;

    console.log({ dataRows });

    const ALL_COLUMNS = ['User Name', 'User Email', 'Client ID', 'Role'];

    const headers = getStyledExportHeaders(ALL_COLUMNS);

    const workbook = XLSX.utils.book_new();

    const rows = dataRows?.map((result) => {
      return [
        {
          v: result.name,
          t: 's',
        },
        {
          v: result.email,
          t: 's',
        },
        {
          v: result.clientId,
          t: 's',
        },
        {
          v: result.role,
          t: 's',
        },
      ];
    });

    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);

    XLSX.utils.sheet_add_aoa(worksheet, [headers], {
      origin: 'A1',
    });

    const fixedSheet = await autoSizeWorksheetWidth(worksheet);

    XLSX.utils.book_append_sheet(workbook, fixedSheet, `Users Client Roles`);
    XLSX.writeFile(workbook, `users-client-roles.xlsx`);
  } catch (error) {
    console.error(error);
  }
};
