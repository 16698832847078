import {
  Box,
  Button,
  Dialog,
  Link,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import FileUploaderCard from '../../../../components/FileUploaderCard';
import { readURLAsBinary } from '../../../../shared/utilities';

import { useState } from 'react';

import XLSX from 'xlsx-js-style';

import StepGuide from '../../../../components/StepGuide';
import { AppState } from '../../../../config/store';

import { colours } from '../../../../config/theme';
import CardTitle from '../../surveys/edit/asset/components/CardTitle';
import {
  assembleSitesAndBuildings,
  BulkUpdateFeedback,
} from './bulk-uploader-builder';
import {
  validateWorkbook,
  ValidationResponse,
} from './bulk-uploader-validation';
import CountryCodesTable from './CountryCodesTable';

const getTemplateSheet = async () => {
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute(
    'href',
    'https://firebasestorage.googleapis.com/v0/b/asseticom-internal-resources/o/Bulk-Sites-And-Buildings-Template.xlsx?alt=media',
  );
  downloadAnchorNode.setAttribute(
    'download',
    'Bulk-Sites-And-Buildings-Template.xlsx',
  );
  document.body.appendChild(downloadAnchorNode);
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};

export default function BulkUploader() {
  const client = useSelector((state: AppState) => state.firestore.data.client);

  const [validationErrors, setValidationErrors] =
    useState<ValidationResponse[]>();

  const [progressFeedback, setProgressFeedback] = useState<
    BulkUpdateFeedback[]
  >([]);

  const [showCountryCodes, setShowCountryCodes] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>();

  const setFileData = async (file, id) => {
    setValidationErrors([]);

    const data = await readURLAsBinary(file);
    setValidationErrors([]);
    setProgressFeedback([]);
    setFileName(file.name);

    const workbook = XLSX.read(data, {
      type: 'binary',
      sheets: ['Sites and Buildings'],
    });

    workbook.SheetNames.forEach((sheetName) => {
      console.log('sheetName', sheetName);
    });

    const validationResponses = validateWorkbook(workbook);

    console.log({ validationResponses });

    const errors = Object.values(validationResponses).filter(
      (response) => response.success === false,
    );

    if (errors.length) {
      return setValidationErrors(errors);
    }

    assembleSitesAndBuildings({
      clientId: client.clientId,
      workbookData: validationResponses,
      feedbackCallback: (feedback: BulkUpdateFeedback) => {
        setProgressFeedback((prev) => [...prev, feedback]);
      },
    });
  };

  return (
    <Paper>
      <Dialog
        fullScreen={false}
        open={showCountryCodes}
        onClose={() => setShowCountryCodes(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {showCountryCodes ? (
          <>
            <CardTitle text="Country Codes" />
            <Box
              p={2}
              justifyContent="center"
              display="flex"
              borderBottom={`1px solid ${colours.grey5}`}
            >
              <CountryCodesTable />
            </Box>
          </>
        ) : null}
      </Dialog>
      <Box p={2}>
        <Stack alignItems="center">
          <StepGuide
            stepNumber={1}
            text="Download the template file and then add your data."
          />
          <Typography color="secondary" variant="body1">
            For a list of valid country codes{' '}
            <Link
              sx={{ cursor: 'pointer' }}
              onClick={() => setShowCountryCodes(true)}
            >
              click here
            </Link>
          </Typography>
        </Stack>
        <Box mt={2} display="flex" flexDirection="column" alignItems="center">
          <Button
            onClick={getTemplateSheet}
            variant="contained"
            color="secondary"
          >
            Download Template
          </Button>
        </Box>
        <Box my={4}>
          <StepGuide
            stepNumber={2}
            text="Upload your completed template file."
          />
          <FileUploaderCard
            title="Bulk Upload"
            onChange={(file) => setFileData(file, 'siteAndBuildingList')}
          />
        </Box>
        {validationErrors?.map((response, index) => (
          <Box key={index} mt={2}>
            <StepGuide
              stepNumber={3}
              text="Fix the following errors, then upload your file again."
            />
            <Typography variant="caption" gutterBottom>
              {response.identifier}
            </Typography>
            {response.errors.map((error, index) => (
              <Typography
                key={index}
                variant="caption"
                color="error"
                sx={{ display: 'block' }}
              >
                {error}
              </Typography>
            ))}
          </Box>
        ))}
        {progressFeedback.map((feedback, index) => (
          <Typography
            key={index}
            variant="caption"
            gutterBottom
            color={feedback.error ? 'error' : 'primary'}
            sx={{ display: 'block' }}
          >
            {feedback.status}...
          </Typography>
        ))}
      </Box>
    </Paper>
  );
}
