import firebase from 'firebase/app';
import XLSX from 'xlsx-js-style';
import {
  autoSizeWorksheetWidth,
  getAllCombinedRoomsForSurvey,
  getSafeExcelSheetName,
  getStyledExportHeaders,
  statusToString,
  statuses,
} from '../../../../../shared/utilities';
import { SurveyProps } from '../../../../../types/survey';

// Get Firestore instance
const firestore = firebase.firestore();

interface ExportRoomSchedule {
  clientId: string | undefined;
  survey: SurveyProps;
}

export default async function exportRoomSchedule({
  clientId,
  survey,
}: ExportRoomSchedule) {
  if (!clientId || !survey) {
    throw new Error('Missing required parameters');
  }
  const usedRooms = await firestore
    .collection('clients')
    .doc(clientId)
    .collection('surveys')
    .doc(survey.id)
    .collection('rooms')
    .where('deleted', '==', 0)
    .get();

  const roomList = usedRooms.docs.map((r) => {
    const roomData = r.data();
    return {
      floor: roomData.floor,
      name: roomData.name,
      id: r.id,
      assets: roomData.assetsNumCreated - roomData.assetsNumDeleted || 0,
      partials: roomData.partialsNumCreated - roomData.partialsNumDeleted || 0,
      flagged: roomData.assetsNumFlagged || 0,
      notes: roomData.notes,
      status: roomData.status,
      images: roomData.images,
      areaFloor: roomData.areaFloor,
      areaWall: roomData.areaWall,
    };
  });
  const allRooms = getAllCombinedRoomsForSurvey(survey, roomList);

  const ALL_COLUMNS = [
    'Survey Name',
    'Site Name',
    'Building Name',
    'Floor Name',
    'Room Name',
    'Area Floor',
    'Area Wall',
    'Assets',
    'Partials',
    'Flagged',
    'Notes',
    'Status',
    'Image 1',
    'Image 2',
    'Image 3',
    'Image 4',
    'Image 5',
  ];

  const headers = getStyledExportHeaders(ALL_COLUMNS);

  const workbook = XLSX.utils.book_new();
  const roomRows = allRooms.map((room) => {
    return [
      {
        v: survey.name,
        t: 's',
      },
      {
        v: survey.siteName,
        t: 's',
      },
      {
        v: survey.buildingName,
        t: 's',
      },
      {
        v: room.floor,
        t: 's',
      },
      {
        v: room.name,
        t: 's',
      },
      {
        v: (room.areaFloor || 0).toFixed(2),
        t: 'n',
      },
      {
        v: (room.areaWall || 0).toFixed(2),
        t: 'n',
      },
      {
        v: room.assets || 0,
        t: 'n',
      },
      {
        v: room.partials || 0,
        t: 'n',
      },
      {
        v: room.flagged || 0,
        t: 'n',
      },
      {
        v: room.notes || '',
        t: 's',
      },
      {
        v: statusToString(room.status || statuses.NOT_STARTED),
        t: 's',
      },
      {
        v: room.images?.[0] ? 'CLICK TO OPEN' : '',
        t: 's',
        l: room.images?.[0] ? { Target: room.images?.[0].uri } : undefined,
      },
      {
        v: room.images?.[1] ? 'CLICK TO OPEN' : '',
        t: 's',
        l: room.images?.[1] ? { Target: room.images?.[0].uri } : undefined,
      },
      {
        v: room.images?.[2] ? 'CLICK TO OPEN' : '',
        t: 's',
        l: room.images?.[2] ? { Target: room.images?.[0].uri } : undefined,
      },
      {
        v: room.images?.[3] ? 'CLICK TO OPEN' : '',
        t: 's',
        l: room.images?.[3] ? { Target: room.images?.[0].uri } : undefined,
      },
      {
        v: room.images?.[4] ? 'CLICK TO OPEN' : '',
        t: 's',
        l: room.images?.[4] ? { Target: room.images?.[0].uri } : undefined,
      },
    ];
  });

  // const worksheet = XLSX.utils.json_to_sheet(roomRows);
  const worksheet = XLSX.utils.aoa_to_sheet([headers, ...roomRows]);
  XLSX.utils.sheet_add_aoa(worksheet, [headers], {
    origin: 'A1',
  });

  const workbookName = getSafeExcelSheetName(
    `${survey.name} Room Export`.substring(0, 31),
  );
  const fixedWorksheet = await autoSizeWorksheetWidth(worksheet);
  XLSX.utils.book_append_sheet(workbook, fixedWorksheet, workbookName);
  XLSX.writeFile(workbook, `${workbookName}.xlsx`);
}
