import 'firebase/firestore';
import 'firebase/functions';

import { BigBatch } from '@qualdesk/firestore-big-batch';
import firebase from 'firebase/app';
import { fetchGeocoderData } from '../../../../shared/geo-location/getGeoData';
import { getLastUpdated } from '../../../../shared/logTools';
import { stringToHash } from '../../../../shared/utilities';
import { BuildingProps } from '../../../../types/building';
import { SiteProps } from '../../../../types/site';
import { SitesAndBuildingsWorkbookValidationResponse } from './bulk-uploader-validation';

// Get Firestore instance
const firestore = firebase.firestore();

export type BulkUpdateFeedback = {
  error?: boolean;
  status: string;
};

type SiteBuildCache = {
  [siteId: string]: SiteProps;
};
type BuildingBuildCache = {
  [buildingId: string]: BuildingProps;
};

const sitesCache = {} as SiteBuildCache;
const buildingsCache = {} as BuildingBuildCache;

export async function assembleSitesAndBuildings({
  clientId,
  workbookData,
  feedbackCallback,
}: {
  clientId: string;
  workbookData: SitesAndBuildingsWorkbookValidationResponse;
  feedbackCallback: (feedback: BulkUpdateFeedback) => void;
}) {
  try {
    feedbackCallback({ status: `Creating Sites and Buildings...` });

    feedbackCallback({
      status: ``,
    });

    const rowsData = workbookData.Rows;
    console.log('rowsData', rowsData);

    const clientDocRef = firestore.collection('clients').doc(clientId);

    const clientDoc = await clientDocRef.get();

    if (clientDoc.exists === false) {
      throw feedbackCallback({
        status: `Client ${clientId} not found`,
        error: true,
      });
    }

    for (const row of rowsData.data) {
      const siteName = row['Site Name'];
      console.log({ siteName });

      const batch = new BigBatch({ firestore });

      const sitesRef = clientDocRef.collection('sites');

      const siteBaseData = {
        name: siteName,
        address1: row['Site Address 1'],
        address2: row['Site Address 2'] ?? '',
        townCity: row['Site Town/City'],
        county: row['Site County'],
        region: row['Site Region'],
        postCode: row['Site Post Code'],
        countryCode: row['Site Country Code'],
      };

      const siteId = stringToHash(JSON.stringify(siteBaseData));

      const siteDocRef = sitesRef.doc(siteId);

      const siteExists = (await siteDocRef.get()).exists;

      let siteLocationData = { lat: 0, lng: 0 };

      if (sitesCache[siteId]) {
        console.log('siteId already exists', siteId);
        siteLocationData.lat = sitesCache[siteId].lat ?? 0;
        siteLocationData.lng = sitesCache[siteId].lng ?? 0;
      } else if (siteExists) {
        feedbackCallback({
          status: `Site ${siteName} already created...skipping`,
        });
      } else {
        feedbackCallback({
          status: `Creating site ${siteName}`,
        });
        console.log('siteId does not exist', siteId);

        feedbackCallback({
          status: `Fetching site geolocation data using post code ${row['Site Post Code']}`,
        });
        siteLocationData = await fetchGeocoderData({
          countryCode: row['Site Country Code'],
          postCode: row['Site Post Code'],
        });
        console.log('siteLocationData', siteLocationData);

        feedbackCallback({
          status: `Site geolocation data successfully retrieved`,
        });

        const fullSiteData = {
          ...siteBaseData,
          deleted: 0,
          clientId,
          clientName: clientDoc.data()?.name ?? '',
          buildingsNumCreated: 0,
          buildingsNumDeleted: 0,
          surveysNumCreated: 0,
          surveysNumDeleted: 0,
          surveysNumCompleted: 0,
          surveysNumInProgress: 0,
          surveysNumNotStarted: 0,
          assetsNumCreated: 0,
          assetsNumDeleted: 0,
          partialsNumCreated: 0,
          partialsNumDeleted: 0,
          assetsNumFlagged: 0,
          created: Date.now(),
          lat: siteLocationData.lat ?? 0,
          lng: siteLocationData.lng ?? 0,
          ...getLastUpdated(),
          id: siteId,
        };

        //todo: add the site to the batch
        batch.set(siteDocRef, fullSiteData, { merge: true });

        console.log({ fullSiteData });

        sitesCache[siteId] = fullSiteData;

        feedbackCallback({
          status: `${siteName} successfully created`,
        });
      }

      const buildingName = row['Building Name'];

      if (!buildingName) {
        continue;
      }

      const buildingBaseData = {
        name: buildingName,
        address1: row['Building Address 1'] ?? '',
        address2: row['Building Address 2'] ?? '',
        townCity: row['Building Town/City'] ?? '',
        county: row['Building County'] ?? '',
        region: row['Building Region'] ?? '',
        postCode: row['Building Post Code'] ?? '',
        countryCode: row['Building Country Code'] ?? '',
        lat: 0,
        lng: 0,
      };
      const buildingId = stringToHash(JSON.stringify(buildingBaseData));
      const buildingDocRef = siteDocRef.collection('buildings').doc(buildingId);
      const buildingExists = (await buildingDocRef.get()).exists;

      if (buildingsCache[buildingId] || buildingExists) {
        console.log('buildingId already exists', buildingId);
        feedbackCallback({
          status: `Building ${buildingName} already created...skipping`,
        });
      } else {
        feedbackCallback({
          status: `Creating building ${buildingName}`,
        });

        if (buildingBaseData.postCode && buildingBaseData.countryCode) {
          feedbackCallback({
            status: `Fetching building geolocation data using post code ${buildingBaseData.postCode}`,
          });
          const buildingLocationData = await fetchGeocoderData({
            countryCode: buildingBaseData.countryCode,
            postCode: buildingBaseData.postCode,
          });
          console.log('buildingLocationData', buildingLocationData);
          buildingBaseData.lat = buildingLocationData.lat ?? 0;
          buildingBaseData.lng = buildingLocationData.lng ?? 0;
          feedbackCallback({
            status: `Building geolocation data successfully retrieved`,
          });
        } else {
          buildingBaseData.lat = siteLocationData.lat;
          buildingBaseData.lng = siteLocationData.lng;
        }

        const buildingObj: BuildingProps = {
          ...buildingBaseData,
          assetsNumCreated: 0,
          assetsNumDeleted: 0,
          assetsNumFlagged: 0,
          partialsNumCreated: 0,
          partialsNumDeleted: 0,
          created: Date.now(),
          deleted: 0,
          id: buildingId,
          clientId,
          clientName: clientDoc.data()?.name ?? '',
          name: buildingName,
          siteName: siteBaseData.name,
          siteId,
          ...getLastUpdated(),
        };
        batch.set(buildingDocRef, buildingObj, { merge: true });

        console.log({ buildingObj });

        buildingsCache[buildingId] = buildingObj;

        feedbackCallback({
          status: `${buildingName} successfully created`,
        });
      }

      await batch.commit();

      feedbackCallback({
        status: ``,
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      feedbackCallback({
        status: `Error: ${error.message}`,
        error: true,
      });
    } else {
      // Handle unknown error types
      feedbackCallback({
        status: 'An unknown error occurred',
        error: true,
      });
    }
  } finally {
    feedbackCallback({
      status: 'Finished bulk import',
    });
  }
}
