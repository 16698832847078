import {
  getRandomHyphenatedStringFromArray,
  getStorageForPath,
  getUserRoleStringId,
} from '../shared/utilities';
import { setLoading, setSnackMessage } from './ui';

import { PATHS } from '../config/constants';
import { functions } from '../config/store';
import { getLastUpdated } from '../shared/logTools';

export const onUserSettingsUpdate =
  ({ formData, croppedImage, uid, dirtyFields }) =>
  async (dispatch, getState, { getFirebase }) => {
    try {
      const firestore = getFirebase().firestore();

      const {
        nameFirst,
        nameLast,
        email,
        newPassword,
        confirmPassword,
        exportEmails,
      } = formData;
      const userData = {};
      const updateProfileObj = {};
      const updateRoleObject = {};

      if (croppedImage) {
        const userImageStorage = getStorageForPath(PATHS.STORAGE.IMAGE_USER);
        const imagePath = `${uid}/profile.jpeg`;
        const userImageRef = userImageStorage.ref(`${imagePath}`);
        await userImageRef.putString(croppedImage, 'data_url');
        userData.imageURL = await userImageRef.getDownloadURL();
        updateProfileObj.photoURL = userData.imageURL;
        updateRoleObject.imageURL = userData.imageURL;
      }

      const { currentUser } = getFirebase().auth();

      if (dirtyFields.email) {
        const emailToLowerCase = email.toLowerCase();
        await currentUser
          .updateEmail(emailToLowerCase)
          .then(() => {
            userData.email = emailToLowerCase;
            updateRoleObject.email = emailToLowerCase;
          })
          .catch((error) => {
            console.error(error);
          });
      }

      if (
        dirtyFields.newPassword &&
        dirtyFields.confirmPassword &&
        newPassword === confirmPassword
      ) {
        await currentUser.updatePassword(newPassword).catch((error) => {
          console.error(error);
        });
      }

      if (dirtyFields.exportEmails) {
        userData.exportEmails = exportEmails;
        updateRoleObject.exportEmails = exportEmails;
      }

      if (dirtyFields.nameFirst || dirtyFields.nameLast) {
        updateProfileObj.displayName = `${nameFirst} ${nameLast}`;
        updateRoleObject.nameFirst = nameFirst;
        updateRoleObject.nameLast = nameLast;
        userData.nameFirst = nameFirst;
        userData.nameLast = nameLast;
      }

      if (updateProfileObj.photoURL || updateProfileObj.displayName) {
        await currentUser
          .updateProfile({
            displayName: `${nameFirst} ${nameLast}`,
            photoURL: userData.imageURL || '',
          })
          .catch((error) => {
            console.error(error);
          });
      }

      // only update user doc if has any updated values assigned
      if (Object.keys(userData).length) {
        await firestore
          .collection('users')
          .doc(uid)
          .update({ ...userData, ...getLastUpdated() });
      }

      if (
        updateRoleObject.email ||
        updateRoleObject.nameFirst ||
        updateRoleObject.nameLast ||
        updateRoleObject.imageURL ||
        updateRoleObject.exportEmails !== undefined
      ) {
        const roles = await firestore
          .collection('roles')
          .where('uid', '==', uid)
          .get();
        const promises = [];
        roles.forEach((doc) => {
          promises.push(
            doc.ref.update({ ...updateRoleObject, ...getLastUpdated() }),
          );
        });
        await Promise.all(promises);
      }

      dispatch(
        setSnackMessage({
          message: `${formData.nameFirst} ${formData.nameLast} updated`,
        }),
      );

      return { user: userData };
    } catch (error) {
      console.error(error);
      return { error };
    }
  };

export const onClientUserUpdate =
  ({ formData, userId }) =>
  async (dispatch, getState, { getFirebase }) => {
    try {
      const { clientId } = getState().firestore.data.client;

      dispatch(
        setSnackMessage({
          message: `${formData.nameFirst} ${formData.nameLast} updated`,
        }),
      );

      const updatedClientUser = await functions.httpsCallable(
        'updateClientUserRole',
      )({
        ...formData,
        email: formData.email.toLowerCase(),
        clientId,
      });

      return { user: updatedClientUser };
    } catch (error) {
      console.error(error);
      return { error };
    }
  };

export const onUserSubmit =
  ({ formData, userId }) =>
  async (dispatch, getState, { getFirebase }) => {
    try {
      const { clientId, name } = getState().firestore.data.client;
      dispatch(
        setLoading({
          loadingMessage: 'Creating user, please wait...',
        }),
      );
      const newUser = await functions.httpsCallable('createUserAccount')({
        ...formData,
        email: formData.email.toLowerCase(),
        clientId,
        clientName: name,
      });
      dispatch(setLoading(null));
      return newUser.data;
    } catch (error) {
      console.error(error);
      return { error };
    }
  };

export const onUserDelete =
  ({ userId, name }) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      const clientId = getState().firestore.data.client.clientId;
      const firestore = getFirestore();
      const roleDoc = firestore
        .collection('roles')
        .doc(getUserRoleStringId(userId, clientId));
      roleDoc.update({ deleted: Date.now(), ...getLastUpdated() });
      dispatch(
        setSnackMessage({
          message: `${name} deleted`,
        }),
      );
      return true;
    } catch (error) {
      console.error(error);
      return { error };
    }
  };

export const checkForUserByEmail =
  ({ email }) =>
  async (dispatch, getState, { getFirebase }) => {
    try {
      const firestore = getFirebase().firestore();
      const snapshot = await firestore
        .collection('users')
        .where('email', '==', email.toLowerCase())
        .limit(1)
        .get();
      if (snapshot.size === 1) {
        return Promise.resolve(snapshot.docs[0]);
      }
      return Promise.resolve();
    } catch (error) {
      return error;
    }
  };

export const addNewTestUser =
  () =>
  async (dispatch, getState, { getFirebase }) => {
    try {
      const { clientId } = getState().firestore.data.client;
      const randomStringFirstName = getRandomHyphenatedStringFromArray(
        [
          'Bob',
          'Billy',
          'John',
          'Joe',
          'Dwayne',
          'Mike',
          'Phil',
          'Terry',
          'Roy',
          'Phil',
          'Brian',
          'Akash',
          'Raj',
          'Yusuf',
          'Christiano',
          'Lionel',
          'Barry',
          'Sebastian',
          'Desmond',
          'Amir',
        ],
        0.05,
      );

      const randomStringLastName = getRandomHyphenatedStringFromArray(
        [
          'Hancock',
          'Parker',
          'Bowles',
          'Smith',
          'Jones',
          'Cooper',
          'Brown',
          'Messi',
          'Ronaldo',
          'Zidane',
          'Johnson',
          'Hull',
          'Nadir',
          'Khan',
          'Osgood',
          'Kanu',
          'Parkinson',
          'Jackson',
          'Phillips',
          'Savage',
        ],
        0.05,
      );

      const createdUserAccount = await functions.httpsCallable(
        'createUserAccount',
      )({
        clientId,
        nameFirst: randomStringFirstName,
        nameLast: randomStringLastName,
        email: `${randomStringFirstName.toLowerCase()}${randomStringLastName.toLowerCase()}@asseticom.co.uk`,
        role: '20',
      });
      return { user: createdUserAccount.data.user };
      // return {};
    } catch (error) {
      console.error(error);
      return { error };
    }
  };
