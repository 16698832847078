import ImageTableCell from '../../../../../components/ImageTableCell';
import LastUpdatedTableCell from '../../../../../components/LastUpdatedTableCell';
import StatusStringTableCell from '../../../../../components/StatusStringTableCell';
import {
  RIGHT_ALIGN_BODY,
  RIGHT_ALIGN_HEADER,
} from '../../../../../config/constants';

export const EMPTY_ROOM = {
  name: '',
  floor: '',
  areaWall: 0,
  areaFloor: 0,
  notes: '',
};

export const TABLE_COLUMNS = [
  {
    accessorKey: 'name',
    header: 'Name',
    Cell: ImageTableCell,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    Cell: StatusStringTableCell,
  },
  {
    accessorKey: 'lastUpdated.time',
    header: 'Last Updated',
    Cell: LastUpdatedTableCell,
  },

  {
    accessorKey: 'areaFloor',
    header: 'Area Floor',
    ...RIGHT_ALIGN_HEADER,
    ...RIGHT_ALIGN_BODY,
  },
  {
    accessorKey: 'areaWall',
    header: 'Area Wall',
    ...RIGHT_ALIGN_HEADER,
    ...RIGHT_ALIGN_BODY,
  },
  {
    accessorKey: 'assets',
    header: 'Assets',
    ...RIGHT_ALIGN_HEADER,
    ...RIGHT_ALIGN_BODY,
  },
  {
    accessorKey: 'partials',
    header: 'Partials',
    ...RIGHT_ALIGN_HEADER,
    ...RIGHT_ALIGN_BODY,
  },
  {
    accessorKey: 'flagged',
    header: 'Flagged',
    ...RIGHT_ALIGN_HEADER,
    ...RIGHT_ALIGN_BODY,
  },
];
