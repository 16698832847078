import { Box, Paper, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import FileUploaderCard from '../../../../components/FileUploaderCard';
import { readURLAsBinary } from '../../../../shared/utilities';

import { AppState } from '../../../../config/store';

import XLSX from 'xlsx-js-style';

import { useState } from 'react';
import { assembleSurveys, BulkUpdateFeedback } from './bulk-uploader-builder';
import {
  validateWorkbook,
  ValidationResponse,
} from './bulk-uploader-validation';

export default function BulkUploader() {
  const client = useSelector((state: AppState) => state.firestore.data.client);

  const [fileName, setFileName] = useState<string>();

  const [validationErrors, setValidationErrors] =
    useState<ValidationResponse[]>();

  const [progressFeedback, setProgressFeedback] = useState<
    BulkUpdateFeedback[]
  >([]);

  const setFileData = async (file: File) => {
    const data = await readURLAsBinary(file);
    setValidationErrors([]);
    setProgressFeedback([]);
    setFileName(file.name);

    const workbook = XLSX.read(data, { type: 'binary' });

    const validationResponses = validateWorkbook(workbook);

    const errors = Object.values(validationResponses).filter(
      (response) => response.success === false,
    );

    console.log('errors', errors);

    if (errors.length) {
      return setValidationErrors(errors);
    }

    assembleSurveys({
      clientId: client.clientId,
      workbookData: validationResponses,
      feedbackCallback: (feedback: BulkUpdateFeedback) => {
        setProgressFeedback((prev) => [...prev, feedback]);
      },
    });
  };

  return (
    <>
      <Box mb={2}>
        <FileUploaderCard
          title="Bulk Upload"
          onChange={(file) => setFileData(file)}
          fileName={fileName}
        />
        <Box mt={2}>
          <Paper>
            <Box p={2}>
              <Typography variant="subtitle2" gutterBottom>
                Feedback
              </Typography>
              {validationErrors?.map((response, index) => (
                <Box key={index} mt={2}>
                  <Typography variant="caption" gutterBottom>
                    {response.identifier}
                  </Typography>
                  {response.errors.map((error, index) => (
                    <Typography
                      key={index}
                      variant="caption"
                      color="error"
                      sx={{ display: 'block' }}
                    >
                      {error}
                    </Typography>
                  ))}
                </Box>
              ))}
              {progressFeedback.map((feedback, index) => (
                <Typography
                  key={index}
                  variant="caption"
                  gutterBottom
                  color={feedback.error ? 'error' : 'primary'}
                  sx={{ display: 'block' }}
                >
                  {feedback.status}...
                </Typography>
              ))}
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
}
