import {
  FORM_FIELDS_NAME,
  FORM_FIELD_OPTIONS_NAME,
} from '../../../../../config/constants';
import { FieldError, appendErrors } from 'react-hook-form';
import { ZodIssue, z } from 'zod';

import { FormField } from '../../../../../types/form';
import { removeUndefinedFromObject } from '../../../../../shared/utilities';
import { toNestError } from '@hookform/resolvers';
import { FORM_FIELD_LABEL_SCHEMA } from '../../../../../shared/validation';

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === z.ZodIssueCode.invalid_type) {
    if (issue.expected === 'string') {
      return { message: 'bad type!' };
    }
  }
  if (issue.code === z.ZodIssueCode.invalid_type && issue.received === 'nan') {
    return { message: `Must be a valid number` };
  }
  return { message: ctx.defaultError };
};

const baseSchema = z.object({
  type: z.string().min(1, { message: 'Cannot be empty' }),
  label: FORM_FIELD_LABEL_SCHEMA,
  base: z.boolean(),
  id: z.string(),
  required: z.boolean(),
  disabled: z.boolean().or(z.undefined()),
  hidden: z.boolean(),
  nicheFields: z.array(z.string()),
  nicheFieldsExcludeMode: z.boolean().optional(),
  default: z.string().or(z.number()).optional(),
});

const numberSchema = z.coerce
  .number()
  .multipleOf(1, { message: 'Must be a valid number' })
  .nonnegative();

const decimalSchema = z.coerce
  .number()
  .multipleOf(0.00001, { message: 'Must be a valid decimal' })
  .nonnegative();

function generateNumberSchemaFromField(field: FormField, isDecimal = false) {
  const defaultAsNumber = Number(field.default);
  const minAsNumber = Number(field.min);
  const maxAsNumber = Number(field.max);
  const schema = isDecimal ? decimalSchema : numberSchema;

  if (field.default) {
    return baseSchema.extend({
      default: schema.gte(0).optional(),
      max: schema.gte(defaultAsNumber).optional(),
      min: schema.lte(defaultAsNumber).optional(),
    });
  }

  if ((!field.default && minAsNumber) || maxAsNumber) {
    return baseSchema.extend({
      max: schema.gt(minAsNumber).optional(),
      min: schema.lt(maxAsNumber).optional(),
    });
  }
  return baseSchema;
}

function generateDecimalSchemaFromField(field: FormField) {
  return generateNumberSchemaFromField(field, true);
}

function generateSliderSchemaFromField(field: FormField) {
  const defaultAsNumber = Number(field.default);
  return baseSchema.extend({
    default: z.coerce.number().gte(defaultAsNumber),
    max: z.coerce.number().gte(defaultAsNumber),
    min: z.coerce.number().lte(defaultAsNumber),
  });
}

function generatMultipleOptionsSchemaFromField(field: FormField) {
  const options = field.options?.map((o) => o.value);

  const withOptions = baseSchema.extend({
    options: z
      .array(
        z.object({
          value: z.string().min(1, { message: 'Option cannot be empty' }),
        }),
      )
      .min(1, { message: 'Must specify at least 1 option' }),
  });

  const validOptions = options?.filter((o) => !!o).length === options?.length;

  if (
    typeof field.default === 'string' &&
    validOptions &&
    field.default.length
  ) {
    return withOptions.extend({
      default: z
        .string()
        .refine(
          (val) => {
            return options?.find((option) => val === option);
          },
          (val) => ({
            message: `${val} does not match one of the accepted options: ${options?.join(
              ', ',
            )}`,
          }),
        )
        .optional(),
    });
  }

  return withOptions;
}

export const getSchemaForField = (field: FormField) => {
  switch (field.type) {
    case 'Number':
      return generateNumberSchemaFromField(field);
    case 'Decimal':
      return generateDecimalSchemaFromField(field);
    case 'Radio':
    case 'Select':
      return generatMultipleOptionsSchemaFromField(field);
    case 'Slider':
      return generateSliderSchemaFromField(field);
    default:
      return baseSchema;
  }
};

const parseErrorSchema = (
  zodErrors: ZodIssue[],
  validateAllFieldCriteria: boolean,
) => {
  const errors: Record<string, FieldError> = {};
  for (; zodErrors.length; ) {
    const error = zodErrors[0];
    const { code, message, path } = error;
    const _path = path.join('.');

    if (!errors[_path]) {
      if ('unionErrors' in error) {
        const unionError = error.unionErrors[0].errors[0];

        errors[_path] = {
          message: unionError.message,
          type: unionError.code,
        };
      } else {
        errors[_path] = { message, type: code };
      }
    }

    if ('unionErrors' in error) {
      error.unionErrors.forEach((unionError) =>
        unionError.errors.forEach((e) => zodErrors.push(e)),
      );
    }

    if (validateAllFieldCriteria) {
      const types = errors[_path].types;
      const messages = types && types[error.code];

      errors[_path] = appendErrors(
        _path,
        validateAllFieldCriteria,
        errors,
        code,
        messages,
      ) as FieldError;
    }

    zodErrors.shift();
  }

  return errors;
};

export async function formFieldsSetupResolver(data, context, options) {
  const allValues: any[] = [];
  const allErrors: any[] = [];
  const fields = data[FORM_FIELDS_NAME];

  fields.forEach(async (field: FormField, index: number) => {
    const parsedField = {
      ...field,
      // have to add these two fields manually if they aren't present in existing fields (as they may be from a very old survey)
      base:
        field.base ||
        ['Description', 'Quantity', 'Remaining Life'].includes(field.label)
          ? true
          : false,
      hidden: field.hidden || false,
      id: field.id || field.label,
    };

    const schema = getSchemaForField(parsedField).refine(
      (formField) => {
        // if exclude mode is not defined or is falsey then an empty array is invalid as it means no niche fields are selected
        const noneSelected =
          !formField.nicheFieldsExcludeMode && !formField.nicheFields?.length;

        return noneSelected === false;
      },
      {
        message: 'At least one asset type is required',
        path: ['nicheFields'],
      },
    );

    const result = schema.safeParse(parsedField, { errorMap: customErrorMap });

    if (result.success) {
      const cleaned = removeUndefinedFromObject(result.data);
      allValues[index] = cleaned;
    } else {
      const e = toNestError(
        parseErrorSchema(result.error.issues, true),
        options,
      );
      allErrors[index] = e;
    }
  });
  const output = {
    values: allErrors.length ? {} : { ...data, [FORM_FIELDS_NAME]: allValues },
    errors: allErrors.length
      ? { [FORM_FIELD_OPTIONS_NAME]: [], [FORM_FIELDS_NAME]: allErrors }
      : {},
  };

  return output;
}
