import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm, useWatch } from 'react-hook-form';
import {
  BIG_ASSET_LIST_NUM_ROWS,
  RIGHT_ALIGN_BODY,
  RIGHT_ALIGN_HEADER,
} from '../../../../config/constants';
import { parseXLSX, readURLAsBinary } from '../../../../shared/utilities';
import {
  ASSET_TAXONOMY_SCHEMA,
  COLUMN_NAMES_MAP,
  parseDataWithSchema,
} from '../../../../shared/validation';

import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ZodIssue } from 'zod';
import FileUploaderCard from '../../../../components/FileUploaderCard';
import BackButton from '../../../../components/Form/BackButton';
import ButtonsContainer from '../../../../components/Form/ButtonsContainer';
import ErrorText from '../../../../components/Form/ErrorText';
import NextButton from '../../../../components/Form/NextButton';
import ImportColumnMatcher from '../../../../components/ImportColumnMatcher';
import { useModal } from '../../../../components/Modal';
import Table from '../../../../components/Table';
import ZodIssuesList from '../../../../components/ZodIssuesList';
import { onNewAssetListSubmitted } from '../../../../state/asset-list';
import { addFileObject } from '../../../../state/files';
import { setLoading } from '../../../../state/ui';
import { AssetListForSelection } from '../../../../types/asset-list';

const useStyles = makeStyles(() => ({}));

const columns = [
  {
    accessorKey: 'code3',
    header: COLUMN_NAMES_MAP.CODE_3,
    styles: { width: '100px' },
  },
  { accessorKey: 'label3', header: COLUMN_NAMES_MAP.LABEL_3 },
  { accessorKey: 'uom', header: COLUMN_NAMES_MAP.UOM },
  {
    accessorKey: 'costPerUnit',
    header: COLUMN_NAMES_MAP.COST_PER_UNIT,
    ...RIGHT_ALIGN_HEADER,
    ...RIGHT_ALIGN_BODY,
  },
  {
    accessorKey: 'expectedLife',
    header: COLUMN_NAMES_MAP.EXPECTED_LIFE,
    ...RIGHT_ALIGN_HEADER,
    ...RIGHT_ALIGN_BODY,
  },
  {
    accessorKey: 'uplift',
    header: COLUMN_NAMES_MAP.UPLIFT,
    ...RIGHT_ALIGN_HEADER,
    ...RIGHT_ALIGN_BODY,
  },
];

const DESTINATION_COLUMNS_MAP = {
  [COLUMN_NAMES_MAP.CODE_0]: 'code0',
  [COLUMN_NAMES_MAP.LABEL_0]: 'label0',
  [COLUMN_NAMES_MAP.CODE_1]: 'code1',
  [COLUMN_NAMES_MAP.LABEL_1]: 'label1',
  [COLUMN_NAMES_MAP.CODE_2]: 'code2',
  [COLUMN_NAMES_MAP.LABEL_2]: 'label2',
  [COLUMN_NAMES_MAP.CODE_3]: 'code3',
  [COLUMN_NAMES_MAP.LABEL_3]: 'label3',
  [COLUMN_NAMES_MAP.UOM]: 'uom',
  [COLUMN_NAMES_MAP.COST_PER_UNIT]: 'costPerUnit',
  [COLUMN_NAMES_MAP.EXPECTED_LIFE]: 'expectedLife',
  [COLUMN_NAMES_MAP.UPLIFT]: 'uplift',
  [COLUMN_NAMES_MAP.NOTES]: 'notes',
  [COLUMN_NAMES_MAP.ANNUAL_ENERGY_CONSUMPTION]: 'annualEnergyConsumption',
  [COLUMN_NAMES_MAP.ANNUAL_CARBON_EMISSIONS]: 'annualCarbonEmissions',
  [COLUMN_NAMES_MAP.EMBODIED_CARBON_EMISSIONS]: 'embodiedCarbonEmissions',
  [COLUMN_NAMES_MAP.MAINTENANCE_CODE]: 'maintenanceCode',
  [COLUMN_NAMES_MAP.REF_1]: 'ref1',
  [COLUMN_NAMES_MAP.REF_2]: 'ref2',
  [COLUMN_NAMES_MAP.REF_3]: 'ref3',
  [COLUMN_NAMES_MAP.REF_4]: 'ref4',
  [COLUMN_NAMES_MAP.REF_5]: 'ref5',
};

type AssetTaxonomy = {
  initialRows?: any[];
  initialHeaders?: string[];
  fileName?: string;
  data?: any[];
  rows?: any[];
};

const AddAssetLists = () => {
  const classes = useStyles();
  const LIST_PROP_NAME = 'assetList';
  const dispatch = useDispatch();
  const { showModal } = useModal();
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();
  const [assetList, setAssetList] = useState<AssetTaxonomy | undefined>();
  const [issues, setIssues] = useState<ZodIssue[]>();
  const formNameWatcher = useWatch({ name: 'name', control });

  const setFileData = async (file, id) => {
    const { lastModified, name } = file;

    setIssues(undefined);

    const fileUrl = window.URL.createObjectURL(file);

    dispatch(addFileObject({ fileUrl, fileName: name, id, lastModified }));

    const data = await readURLAsBinary(file);

    const assetListParsed = await parseXLSX<AssetListForSelection>(data);

    setAssetList({
      initialRows: assetListParsed.rows,
      initialHeaders: assetListParsed.headers,
      fileName: file.name,
    });
  };

  const onSubmit = async (formData) => {
    // this is temporary until everyone is on at least app version 2.12.+
    if ((assetList?.rows?.length ?? 0) >= BIG_ASSET_LIST_NUM_ROWS) {
      await showModal({
        title: 'Please Note - Important',
        buttons: {
          confirm: [{ text: 'Ok' }],
        },
        messages: [
          'As you are uploading a large taxonomy, for correct operation of the surveying app, you should ensure your surveyors are using the latest version available on the iOS/Android app stores',
        ],
      });
    }

    dispatch(
      setLoading({
        loadingMessage: 'Uploading asset taxonomy, please wait...',
      }),
    );

    if (assetList?.fileName && assetList?.rows?.length) {
      dispatch(
        onNewAssetListSubmitted({
          name: formData.name,
          fileName: assetList?.fileName,
          assetList: assetList?.rows,
        }),
      );

      dispatch(setLoading(false));
      setAssetList(undefined);
      reset({ name: '' });
    }
  };

  const getTemplateSheet = async () => {
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute(
      'href',
      'https://firebasestorage.googleapis.com/v0/b/asseticom-internal-resources/o/Asset-Taxonomy-Template.xlsx?alt=media',
    );
    downloadAnchorNode.setAttribute('download', 'Asset-Taxonomy-Template.xlsx');
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  const onRemoveTaxonomy = () => {
    setIssues(undefined);
    setAssetList(undefined);
  };

  const onConfirmMapping = (matches) => {
    const HEADERS = Object.keys(matches);
    const mappedRows = assetList?.initialRows?.map((row) => {
      return HEADERS.reduce((acc, value) => {
        return { ...acc, [value]: row[matches[value]] };
      }, {});
    });
    const schema = ASSET_TAXONOMY_SCHEMA;

    const schemaCheck = parseDataWithSchema({
      data: mappedRows,
      schema,
    });

    if (!schemaCheck.success) {
      setIssues(schemaCheck.error.issues);
      return console.log(JSON.stringify(schemaCheck.error.issues, null, 2));
    } else {
      setIssues(undefined);
    }

    //turn the rows into the format that the db expects
    const destinationKeys = Object.keys(DESTINATION_COLUMNS_MAP);

    const rowsParsed = schemaCheck.data.map((row) => {
      return destinationKeys.reduce((acc, value) => {
        return {
          ...acc,
          [DESTINATION_COLUMNS_MAP[value]]: row[value],
        };
      }, {});
    });

    setAssetList({ ...assetList, rows: rowsParsed });
  };

  return (
    <Paper id="main-content">
      <Box p={2}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                alignItems="center"
                flex={1}
                display="flex"
                flexDirection="column"
              >
                <Box mb={2}>
                  <Divider variant="middle">
                    <Chip variant="outlined" label="Step 1" color="primary" />
                  </Divider>
                </Box>
                <Typography color="secondary" variant="body1">
                  Download the asset taxonomy template and fill in the data
                </Typography>
                <Box mt={2}>
                  <Button
                    onClick={getTemplateSheet}
                    variant="contained"
                    color="secondary"
                  >
                    Download Asset Taxonomy Template
                  </Button>
                </Box>
                <Paper sx={{ px: 2, mt: 2 }}>
                  <Typography color="secondary" my={2} fontWeight="bold">
                    Please note the following requirements:
                  </Typography>
                  <Typography color="secondary" paragraph>
                    Codes must not contain spaces
                  </Typography>
                  <Typography color="secondary" paragraph>
                    Codes must be unique across the row
                  </Typography>
                  <Typography color="secondary" paragraph>
                    Each Code 3 must be unique within the Code 3 column
                  </Typography>
                  <Typography color="secondary" paragraph>
                    {COLUMN_NAMES_MAP.UOM} is required
                  </Typography>
                  <Typography color="secondary" paragraph>
                    {COLUMN_NAMES_MAP.COST_PER_UNIT},{' '}
                    {COLUMN_NAMES_MAP.EXPECTED_LIFE} and{' '}
                    {COLUMN_NAMES_MAP.UPLIFT} although recommended, are not
                    required
                  </Typography>
                  <Box mb={2}>
                    <Link
                      href="https://firebasestorage.googleapis.com/v0/b/asseticom-internal-resources/o/Asset-Taxonomy-Example.xlsx?alt=media"
                      target="_blank"
                    >
                      Click here to download an example taxonomy
                    </Link>
                  </Box>
                </Paper>
              </Box>
              <Box mt={4}>
                <Box mb={2}>
                  <Divider variant="middle">
                    <Chip variant="outlined" label="Step 2" color="primary" />
                  </Divider>
                </Box>
                <Typography
                  color="secondary"
                  variant="body1"
                  align="center"
                  mb={2}
                >
                  Upload your completed taxonomy
                </Typography>
                <FileUploaderCard
                  title="Upload taxonomy"
                  onChange={(file) => setFileData(file, LIST_PROP_NAME)}
                  onRemove={assetList?.fileName ? onRemoveTaxonomy : undefined}
                  fileName={assetList?.fileName}
                />
              </Box>

              {assetList?.initialHeaders ? (
                <Box mt={4}>
                  <Box mb={2}>
                    <Divider variant="middle">
                      <Chip variant="outlined" label="Step 3" color="primary" />
                    </Divider>
                  </Box>
                  <Typography
                    color="secondary"
                    variant="body1"
                    align="center"
                    mb={2}
                  >
                    If required, match your import data to the correct columns,
                    then validate it.
                  </Typography>
                  <Box>
                    <ImportColumnMatcher
                      destinationColumns={Object.keys(DESTINATION_COLUMNS_MAP)}
                      sourceColumns={assetList.initialHeaders}
                      onConfirmMapping={onConfirmMapping}
                    />
                  </Box>
                </Box>
              ) : null}

              <ZodIssuesList issues={issues} />

              {assetList?.rows?.length ? (
                <Box mt={4}>
                  <Box mb={2}>
                    <Divider variant="middle">
                      <Chip variant="outlined" label="Step 4" color="primary" />
                    </Divider>
                  </Box>
                  <Typography
                    color="secondary"
                    variant="body1"
                    align="center"
                    mb={2}
                  >
                    Check your data to ensure it is correct
                  </Typography>
                  <Table rows={assetList.rows} columns={columns} />

                  <Box mt={4} mb={2}>
                    <Divider variant="middle">
                      <Chip variant="outlined" label="Step 5" color="primary" />
                    </Divider>
                  </Box>
                  <Typography
                    color="secondary"
                    variant="body1"
                    align="center"
                    mb={2}
                  >
                    Give your taxonomy a meaningful name
                  </Typography>

                  <Box my={2}>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name="name"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          id="name"
                          label="Taxonomy Name"
                          variant="outlined"
                          error={!!errors.name}
                        />
                      )}
                    />
                    {errors.name && <ErrorText />}
                  </Box>

                  <ButtonsContainer>
                    <BackButton hidden />
                    <NextButton
                      text="Save Taxonomy"
                      disabled={!formNameWatcher}
                    />
                  </ButtonsContainer>
                </Box>
              ) : null}
            </Grid>
          </Grid>
        </form>
      </Box>
    </Paper>
  );
};

export default AddAssetLists;
