import 'firebase/firestore';
import 'firebase/functions';
import {
  BASE_FORM_FIELDS,
  BulkSurveysWorkbookValidationResponse,
  FormFieldRowType,
  RoomScheduleRowType,
  SetupRowType,
} from './bulk-uploader-validation';

import { BigBatch } from '@qualdesk/firestore-big-batch';
import firebase from 'firebase/app';
import { UNSPECIFIED_LABEL } from '../../../../config/constants';
import { functions } from '../../../../config/store';
import { getLastUpdated } from '../../../../shared/logTools';
import {
  capitaliseString,
  getImportsSortedByRoom,
  getRandomID,
  getSafeTextFromText,
} from '../../../../shared/utilities';
import { FormField } from '../../../../types/form';
import { BaseRoomScheduleRoomProps } from '../../../../types/room';
import { Statuses } from '../../../../types/status';
import { RoomSchedule, SurveyPropsDraft } from '../../../../types/survey';

const SURVEY_NAMES_DELIMITER = '***';

// Get Firestore instance
const firestore = firebase.firestore();

export type BulkUpdateFeedback = {
  error?: boolean;
  status: string;
};

const documentCache = new Map<
  string,
  firebase.firestore.QueryDocumentSnapshot
>();

async function findUniqueDocument({
  collection,
  conditions,
  entityName,
}: {
  collection: firebase.firestore.Query;
  conditions: { field: string; value: string }[];
  entityName: string;
}): Promise<firebase.firestore.QueryDocumentSnapshot> {
  const cacheKey = `${entityName}-${JSON.stringify({
    collection: (collection as any)._query?.path.segments.join('/'),
    conditions,
  })}`;

  // Check if we have a cached result
  const cachedDocument = documentCache.get(cacheKey);
  if (cachedDocument) {
    return cachedDocument;
  }

  let query = collection.where('deleted', '==', 0);

  conditions.forEach(({ field, value }) => {
    query = query.where(field, '==', value);
  });

  const snapshot = await query.get();

  const conditionsString = conditions
    .map((c, index) => {
      const s = `${c.field}: ${c.value}`;
      return index === 0 ? s : ` and ${s}`;
    })
    .join('');

  if (snapshot.size > 1) {
    throw new Error(
      `More than one ${entityName} with ${conditionsString} exists. Unable to continue`,
    );
  } else if (snapshot.size === 0) {
    throw new Error(`No ${entityName} with ${conditionsString} exists`);
  }

  // Cache the result before returning
  documentCache.set(cacheKey, snapshot.docs[0]);

  return snapshot.docs[0];
}
type SurveyorData = {
  nameFirst: string;
  nameLast: string;
  uid: string;
  email: string;
};

export async function assembleSurveys({
  clientId,
  workbookData,
  feedbackCallback,
}: {
  clientId: string;
  workbookData: BulkSurveysWorkbookValidationResponse;
  feedbackCallback: (feedback: BulkUpdateFeedback) => void;
}) {
  let hasErrors = false;

  function logError(message: string) {
    hasErrors = true;
    feedbackCallback({ status: message, error: true });
  }

  function logInfo(message: string) {
    feedbackCallback({ status: message });
  }

  try {
    const batch = new BigBatch({ firestore });
    const importPromises: (() => Promise<firebase.functions.HttpsCallableResult>)[] =
      [];

    const clientDocumentRef = firestore.collection('clients').doc(clientId);

    const clientDoc = await clientDocumentRef.get();

    if (clientDoc.exists === false) {
      throw feedbackCallback({
        status: `Client ${clientId} not found`,
      });
    }
    const surveySetup = workbookData.Setup.data;
    for (const survey of surveySetup) {
      logInfo(`Creating Survey: ${survey['Survey Name']}`);

      let surveyorData: SurveyorData = {
        nameFirst: 'Not',
        nameLast: 'Assigned',
        uid: UNSPECIFIED_LABEL,
        email: UNSPECIFIED_LABEL,
      };

      if (survey['Surveyor Email'] === undefined) {
        logInfo(`Setting survey as unnassigned`);
      } else {
        // Find surveyor
        const surveyorDoc = await findUniqueDocument({
          collection: firestore.collection('roles'),
          conditions: [
            { field: 'email', value: survey['Surveyor Email'] },
            { field: 'clientId', value: clientDoc.id },
          ],
          entityName: 'surveyor',
        });

        surveyorData = surveyorDoc.data() as SurveyorData;

        logInfo(
          `Assigning to surveyor: ${surveyorDoc.data().nameFirst} ${
            surveyorDoc.data().nameLast
          } with email ${survey['Surveyor Email']}`,
        );
      }

      // Find taxonomy
      const assetListDoc = await findUniqueDocument({
        collection: clientDocumentRef.collection('assetLists'),
        conditions: [{ field: 'name', value: survey['Taxonomy'] }],
        entityName: 'taxonomy',
      });

      logInfo(
        `Using taxonomy: ${survey['Taxonomy']} with id ${assetListDoc.id}`,
      );

      // Find site
      const siteDoc = await findUniqueDocument({
        collection: clientDocumentRef.collection('sites'),
        conditions: [
          { field: 'name', value: capitaliseString(survey['Site Name']) },
        ],
        entityName: 'site',
      });

      logInfo(`Using site: ${survey['Site Name']} with id ${siteDoc.id}`);

      // Find building
      const buildingDoc = await findUniqueDocument({
        collection: clientDocumentRef
          .collection('sites')
          .doc(siteDoc.id)
          .collection('buildings'),
        conditions: [
          { field: 'name', value: capitaliseString(survey['Building Name']) },
        ],
        entityName: 'building',
      });

      logInfo(
        `Using building: ${survey['Building Name']} with id ${buildingDoc.id}`,
      );

      const formFieldsForThisSurvey = workbookData['Form Fields'].data.filter(
        (f) => {
          const surveyNames = f['Survey Names']
            ?.split(SURVEY_NAMES_DELIMITER)
            .map((s) => {
              return s.trim();
            });
          const isBaseField = BASE_FORM_FIELDS.includes(f.Label);

          if (!surveyNames || isBaseField) {
            return true;
          }
          return surveyNames.includes(survey['Survey Name']);
        },
      );

      feedbackCallback({
        status: `Assembling ${formFieldsForThisSurvey.length} form fields`,
      });

      const formFields = [...formFieldsForThisSurvey.map(generateFormField)];

      const roomsForSurveySiteAndBuilding = workbookData[
        'Room Schedule'
      ]?.data?.filter((r) => {
        const surveyNames = r['Survey Names']
          ?.split(SURVEY_NAMES_DELIMITER)
          .map((s) => {
            return s.trim();
          }) ?? [survey['Survey Name']];
        return (
          surveyNames.includes(survey['Survey Name']) &&
          r['Site Name'] === survey['Site Name'] &&
          r['Building Name'] === survey['Building Name']
        );
      });

      const roomSchedule = {
        data: roomsForSurveySiteAndBuilding?.map(generateRoom),
        floors:
          Array.from(
            new Set(roomsForSurveySiteAndBuilding?.map((r) => r.Floor)),
          ) ?? [],
        fileName: '',
        lastModified: 0,
        url: '',
      };

      const newSurveyDoc = clientDocumentRef.collection('surveys').doc();

      const importsForThisSurvey =
        workbookData.Imports?.data.filter((i) => {
          const surveyNames = i['Survey Names']
            ?.split(SURVEY_NAMES_DELIMITER)
            .map((s) => {
              return s.trim();
            }) ?? [survey['Survey Name']];

          return surveyNames.includes(survey['Survey Name']);
        }) ?? [];

      const roomsFromImports: RoomSchedule['data'] = [];
      importsForThisSurvey.forEach((i) => {
        const roomName = i['Room Name'];
        const room = roomSchedule.data.find(
          (r) => r.name === roomName && r.floor === i['Floor'],
        );
        if (!room) {
          const importedRoom = {
            id: getRandomID(),
            name: roomName,
            floor: i['Floor'] ?? '',
            areaFloor: i['Room Area (floor)'] ?? 0,
            areaWall: i['Room Area (wall)'] ?? 0,
            deleted: 0,
            imported: true,
          };
          roomsFromImports.push(importedRoom);
        }
      });

      const finalRoomSchedule = {
        ...roomSchedule,
        data:
          // remove possible duplicates
          [...roomSchedule.data, ...roomsFromImports].filter(
            (item, index, self) =>
              index ===
              self.findIndex(
                (t) => t.name === item.name && t.floor === item.floor,
              ),
          ) ?? [],
      };

      finalRoomSchedule?.data?.length &&
        logInfo(
          `Inserting ${finalRoomSchedule.data.length} rooms into room schedule`,
        );

      const surveyObj = generateSurvey({
        clientDoc,
        assetListDoc,
        surveyorData,
        siteDoc,
        buildingDoc,
        survey,
        formFields,
        roomSchedule: finalRoomSchedule,
      });

      logInfo(`Saving survey to database`);

      const surveyWithId = { ...surveyObj, id: newSurveyDoc.id };

      const surveyDocRef = clientDocumentRef
        .collection('surveys')
        .doc(newSurveyDoc.id);

      batch.set(surveyDocRef, surveyWithId);

      workbookData.Imports?.data.length &&
        feedbackCallback({
          status: `Beginning import of ${importsForThisSurvey.length} assets`,
        });

      const importsSortedByRoom = getImportsSortedByRoom(
        importsForThisSurvey,
        finalRoomSchedule.data,
      );

      const importPromise = () => {
        return functions.httpsCallable('importAssetData')({
          importsSortedByRoom,
          clientId,
          surveyId: newSurveyDoc.id,
          name: survey['Survey Name'],
          siteId: siteDoc.id,
          siteName: siteDoc.data().name,
          buildingId: buildingDoc.id,
          buildingName: survey['Building Name'],
          assetListId: assetListDoc.id,
          formFields,
          roomSchedule: finalRoomSchedule,
          assetsNumImported: 0,
        });
      };

      importsForThisSurvey.length && importPromises.push(importPromise);

      logInfo(`Survey ${survey['Survey Name']} created successfully`);

      logInfo('');
    }
    console.log('Committing batch');
    await batch.commit();
    console.log('batch commited...running imports');
    Promise.all(importPromises.map((fn) => fn()));
    console.log('Done calling imports...operation complete');
  } catch (error) {
    if (error instanceof Error) {
      logError(`Error: ${error.message}`);
      throw error;
    } else {
      logError(`An unknown error occurred`);
    }
  } finally {
    hasErrors
      ? logError(
          'Finished bulk import with errors, no surveys have been created. Please fix them and try again.',
        )
      : logInfo(
          'Finished bulk import successfully! Depending on how many surveys you created, they may take a while to show in the surveys list.',
        );
  }
}

function generateFormField(field: FormFieldRowType): FormField {
  const assembledOptions = Object.entries(field)
    .filter(([key, _]) => key?.startsWith('Option'))
    .map(([_, value]) => ({
      description: '',
      value: String(value).trim(),
    }));
  return {
    id: getSafeTextFromText(field.Label),
    label: field.Label,
    type: field.Type,
    required: field.Required === 'YES',
    options: assembledOptions,
    base: field['Base'] === 'YES',
    disabled: field['Disabled'] === 'YES',
    hidden: field['Hidden'] === 'YES',
    default: field['Default Value'],
    min: field['Minimum Value'],
    max: field['Maximum Value'],
    nicheFieldsExcludeMode: true,
    nicheFields: [],
  };
}

function generateRoom(room: RoomScheduleRowType): BaseRoomScheduleRoomProps {
  return {
    id: getRandomID(),
    name: room['Room Name'],
    floor: room.Floor,
    areaFloor: room['Room Area (floor)'] ?? 0,
    areaWall: room['Room Area (wall)'] ?? 0,
    deleted: 0,
  };
}

function generateSurvey({
  clientDoc,
  assetListDoc,
  surveyorData,
  siteDoc,
  buildingDoc,
  survey,
  formFields,
  roomSchedule,
}: {
  clientDoc: firebase.firestore.DocumentSnapshot;
  assetListDoc: firebase.firestore.QueryDocumentSnapshot;
  surveyorData: SurveyorData;
  siteDoc: firebase.firestore.QueryDocumentSnapshot;
  buildingDoc: firebase.firestore.QueryDocumentSnapshot;
  survey: SetupRowType;
  formFields: FormField[];
  roomSchedule: RoomSchedule;
}): SurveyPropsDraft {
  return {
    assetListId: assetListDoc.id,
    assetsNumCreated: 0,
    assetsNumDeleted: 0,
    assetsNumImported: 0,
    assetsNumFlagged: 0,
    buildingId: buildingDoc.id,
    buildingName: survey['Building Name'],
    clientId: clientDoc.id,
    clientName: clientDoc.data()?.name,
    created: Date.now(),
    deleted: 0,
    estimatedStartDate: survey['Estimated Start Date'],
    estimatedDuration: survey['Estimated Days to Complete'],
    external: survey.External ?? 0,
    floorPlans: [],
    formOptions: { cost: true, unit: true, uplift: true },
    formFields,
    gifa: survey.GIFA ?? 0,
    ...getLastUpdated(),
    name: survey['Survey Name'],
    partialsNumCreated: 0,
    partialsNumDeleted: 0,
    roomSchedule,
    roomsNumCompleted: 0,
    roomsNumDeleted: 0,
    roomsNumInProgress: 0,
    roomsNumNoAccess: 0,
    roomsNumNotStarted: roomSchedule?.data?.length || 0,
    siteId: siteDoc.id,
    siteName: survey['Site Name'],
    status:
      surveyorData.uid === UNSPECIFIED_LABEL
        ? Statuses.UNASSIGNED
        : Statuses.ISSUED,
    surveyType: survey['Survey Type'],
    surveyorEmail: surveyorData.email,
    surveyorId: surveyorData.uid,
    surveyorName: `${surveyorData.nameFirst} ${surveyorData.nameLast}`,
    synced: 0,
    uploadsLost: 0,
    uploadsNotFound: 0,
    uploadsOnOtherDevice: 0,
    uploadsQueueSize: 0,
  };
}
