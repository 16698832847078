import XLSX from 'xlsx-js-style';
import z, { SafeParseReturnType, ZodIssue } from 'zod';
import {
  parseXLSXSheet,
  removeNewLineCharacters,
} from '../../../../shared/utilities';
import {
  COLUMN_NAMES_MAP,
  COST_PER_UNIT_SCHEMA,
  DEFAULT_IMPORT_COLUMNS_SCHEMA,
  FORM_FIELD_LABEL_SCHEMA,
  UOM_SCHEMA,
  UPLIFT_SCHEMA,
} from '../../../../shared/validation';

const ZodInputType = z.enum([
  'Text',
  'Number',
  'Decimal',
  'Select',
  'Slider',
  'Radio',
  'Barcode',
]);

export type InputType = z.infer<typeof ZodInputType>;

export const SHEETS_SCHEMA = z.tuple([
  z.literal('Setup'),
  z.literal('FormFields'),
  z.literal('RoomSchedule'),
  z.literal('Imports'),
]);

export const SETUP_HEADERS_SCHEMA = z.tuple([
  z.literal('Survey Name'),
  z.literal('Site Name'),
  z.literal('Building Name'),
  z.literal('GIFA'),
  z.literal('External'),
  z.literal('Estimated Start Date'),
  z.literal('Estimated Days to Complete'),
  z.literal('Surveyor Email'),
  z.literal('Survey Type'),
  z.literal('Taxonomy'),
]);

export const dateSchema = z.union([
  z
    .string()
    .regex(/^\d{2}\/\d{2}\/\d{4}$/, 'Date format must be DD/MM/YYYY')
    .refine((dateStr) => {
      const [day, month, year] = dateStr.split('/').map(Number);
      const date = new Date(year, month - 1, day);
      return (
        date.getFullYear() === year &&
        date.getMonth() === month - 1 &&
        date.getDate() === day
      );
    }, 'Invalid Date')
    .transform((dateStr) => {
      //convert to epoch date
      const [day, month, year] = dateStr.split('/').map(Number);
      const date = new Date(year, month - 1, day);
      return date.getTime();
    }),
  z.number().transform((num) => {
    //convert to epoch date
    const date = (num - 25569) * 86400 * 1000;
    return date;
  }),
  z.undefined().refine((input) => {
    return false;
  }, 'Please provide a valid date with the format DD/MM/YYYY'),
]);

export const SETUP_ROWS_SCHEMA = z.object({
  'Survey Name': z.string().min(1).trim(),
  'Site Name': z.string().min(1).trim(),
  'Building Name': z.string().min(1).trim(),
  GIFA: z.number().nonnegative().default(0),
  External: z.number().nonnegative().default(0),
  'Estimated Start Date': dateSchema,
  'Estimated Days to Complete': z.number().nonnegative().min(1).default(1),
  'Surveyor Email': z.string().email().optional(),
  'Survey Type': z.enum(['M&E', 'Fabric', 'M&E and Fabric', 'Other', 'Test']),
  Taxonomy: z.string().min(1).trim(),
});

export const FORM_FIELDS_HEADERS_SCHEMA = z
  .tuple([
    z.literal('Survey Names'),
    z.literal('Label'),
    z.literal('Type'),
    z.literal('Default Value'),
    z.literal('Minimum Value'),
    z.literal('Maximum Value'),
    z.literal('Required'),
    z.literal('Hidden'),
    z.literal('Disabled'),
  ])
  .rest(z.string())
  .superRefine((values, ctx) => {
    const defaultOptionColumn = values.indexOf('Disabled') + 1;
    values.slice(defaultOptionColumn + 1).forEach((value, index) => {
      if (!/^Option \d+$/.test(value)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Additional columns must be in format "Option X" where X is a number, received "${value}"`,
          path: [index + 9],
        });
      }
    });
  });

export function inputTypeToZodType(inputType: InputType) {
  const typeToZodType = {
    Text: z.string().trim(),
    Number: z.number(),
    Decimal: z.number(),
    Select: z.string().trim(),
    Slider: z.number(),
    Radio: z.string().trim(),
    Barcode: z.string().trim(),
  };
  return typeToZodType[inputType] ?? undefined;
}

export const BASE_FORM_FIELDS = ['Quantity', 'Remaining Life', 'Description'];

const FORM_FIELDS_ROWS_SCHEMA_WITHOUT_OPTIONS = z.object({
  'Survey Names': z.string().min(1).trim().optional(),
  Label: FORM_FIELD_LABEL_SCHEMA,
  Type: ZodInputType,
  'Default Value': z.union([z.string().trim(), z.number()]).optional(),
  'Minimum Value': z.number().optional(),
  'Maximum Value': z.number().optional(),
  Required: z.enum(['YES', 'NO']).optional().default('NO'),
  Hidden: z.enum(['YES', 'NO']).optional().default('NO'),
  Disabled: z.enum(['YES', 'NO']).optional().default('NO'),
});

export type BulkImportFormFieldWithoutOptions = z.infer<
  typeof FORM_FIELDS_ROWS_SCHEMA_WITHOUT_OPTIONS
>;

export const FORM_FIELDS_ROWS_SCHEMA =
  FORM_FIELDS_ROWS_SCHEMA_WITHOUT_OPTIONS.catchall(
    z.union([z.string(), z.number()]).optional(),
  )
    .transform((val) => {
      if (val.Type === 'Select' || val.Type === 'Radio') {
        val['Default Value']
          ? (val['Default Value'] = String(val['Default Value'])).trim()
          : (val['Default Value'] = '');
      }

      //get all the options from the object and conver to string
      const optionKeys = Object.keys(val).filter((key) =>
        /^Option \d+$/.test(key),
      );
      optionKeys.forEach((key) => {
        const optionNumber = key.replace('Option ', '');
        val[`Option ${optionNumber}`] = String(val[key]).trim();
      });

      const isBaseField = BASE_FORM_FIELDS.includes(val.Label);

      // if the field is a base field, set the Base value to YES
      // non-base fields should have the Disabled value set to false regardless of the input
      // this mimics the behaviour of the ui version
      return isBaseField
        ? { ...val, Base: 'YES' }
        : { ...val, Disabled: false };
    })
    .superRefine((data, ctx) => {
      const {
        Type,
        'Default Value': defaultValue,
        'Minimum Value': minValue,
        'Maximum Value': maxValue,
      } = data;

      const validateType = (
        value: string | number | undefined,
        type: string,
      ) => {
        const response = { valid: true, dataType: 'string' };
        if (typeof value === 'undefined') return response;
        switch (type) {
          case 'Number':
          case 'Slider':
            return { valid: !isNaN(Number(value)), dataType: 'number' };
          case 'Decimal':
            return {
              valid: !isNaN(parseFloat(String(value))),
              dataType: 'decimal',
            };
          case 'Text':
          case 'Select':
          case 'Radio':
            return { valid: typeof value === 'string', dataType: 'string' };
          case 'Barcode':
            return {
              valid: typeof value === 'string' || !isNaN(Number(value)),
              dataType: 'decimal',
            };
          default:
            return { valid: false, dataType: 'string' };
        }
      };

      const optionKeys = Object.keys(data).filter((key) =>
        /^Option \d+$/.test(key),
      );
      const options = optionKeys.map((key) => data[key]);

      if (
        defaultValue &&
        options.length > 0 &&
        !options.includes(defaultValue)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Default Value provided "${defaultValue}" must be one of the supplied options - ${options.join(
            ', ',
          )}`,
          path: ['Default Value'],
        });
      }

      const defaultValidator = validateType(defaultValue, Type);

      if (defaultValidator.valid === false) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Default Value must be a valid ${defaultValidator.dataType}`,
          path: ['Default Value'],
        });
      }

      const minValidator = validateType(minValue, Type);

      if (minValidator.valid === false) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Minimum Value must be a valid ${minValidator.dataType}`,
          path: ['Minimum Value'],
        });
      }

      const maxValidator = validateType(maxValue, Type);

      if (maxValidator.valid === false) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Maximum Value must be a valid ${maxValidator.dataType}`,
          path: ['Maximum Value'],
        });
      }

      if (minValue && maxValue && minValue > maxValue) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Minimum Value must be less than Maximum Value',
        });
      }

      if (
        typeof defaultValue === 'number' &&
        minValue &&
        defaultValue < minValue
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            'Default Value must be greater than or equal to Minimum Value',
        });
      }

      if (
        typeof defaultValue === 'number' &&
        maxValue &&
        defaultValue > maxValue
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Default Value must be less than or equal to Maximum Value',
        });
      }
    });

export type BulkImportFormField = BulkImportFormFieldWithoutOptions & {
  [key: `Option${number}`]: string | number | undefined;
};

export const ROOM_SCHEDULE_HEADERS_SCHEMA = z.tuple([
  z.literal('Survey Names'),
  z.literal(COLUMN_NAMES_MAP.ROOM_NAME),
  z.literal('Site Name'),
  z.literal('Building Name'),
  z.literal(COLUMN_NAMES_MAP.FLOOR),
  z.literal(COLUMN_NAMES_MAP.ROOM_AREA_WALL),
  z.literal(COLUMN_NAMES_MAP.ROOM_AREA_FLOOR),
  z.literal(COLUMN_NAMES_MAP.NOTES),
]);

export const ROOM_SCHEDULE_ROWS_SCHEMA = z.object({
  ['Survey Names']: z.string().min(1).trim().optional(),
  [COLUMN_NAMES_MAP.ROOM_NAME]: z.string().min(1).trim(),
  ['Site Name']: z.string().min(1).trim(),
  ['Building Name']: z.string().min(1).trim(),
  [COLUMN_NAMES_MAP.FLOOR]: z.string().min(1).trim(),
  [COLUMN_NAMES_MAP.ROOM_AREA_WALL]: z.number().nonnegative().optional(),
  [COLUMN_NAMES_MAP.ROOM_AREA_FLOOR]: z.number().nonnegative().optional(),
  [COLUMN_NAMES_MAP.NOTES]: z.string().trim().optional(),
});

type ErrorMessageBuilder = (issue: ZodIssue, lineNumber?: number) => string;

const defaultMessageBuilder: ErrorMessageBuilder = (
  issue: ZodIssue,
  lineNumber?: number,
): string => {
  const rowInfo = lineNumber !== undefined ? ` [ Row: ${lineNumber} ]` : '';
  const pathInfo =
    issue.path.length > 0 ? ` [ Column: ${issue.path.join('.')} ]` : '';

  switch (issue.code) {
    case 'invalid_literal':
    case 'invalid_type':
      return `Expected "${issue.expected}, received ${issue.received}"${pathInfo}${rowInfo}`;
    case 'invalid_enum_value':
      return `Expected one of "${issue.options.join(' | ')}, received ${
        issue.received
      }"${pathInfo}${rowInfo}`;
    case 'custom':
      return `${issue.message}${pathInfo}${rowInfo}`;
    default:
      return `${issue.message}${pathInfo}${rowInfo}`;
  }
};

export type ValidationResponse<T = unknown> = {
  success: boolean;
  identifier: string;
  errors: string[];
  data: T[];
};

export function handleValidationResponse<T>({
  response,
  identifier,
  rowNumber: lineNumber,
}: {
  response: SafeParseReturnType<unknown, T>;
  identifier: string;
  rowNumber?: number | undefined;
}): ValidationResponse<T> {
  if (response.success) {
    return {
      success: true,
      identifier,
      data: [response.data],
      errors: [],
    };
  }

  return {
    success: false,
    identifier,
    errors: response.error.issues.map((issue) =>
      defaultMessageBuilder(issue, lineNumber),
    ),
    data: [],
  };
}

const IMPORT_DATA = {
  Setup: 'Setup',
  FormFields: 'Form Fields',
  RoomSchedule: 'Room Schedule',
  Imports: 'Imports',
} as const;

// Add these type definitions near the top of the file
export type SetupRowType = z.infer<typeof SETUP_ROWS_SCHEMA>;
export type FormFieldRowType = z.infer<typeof FORM_FIELDS_ROWS_SCHEMA>;
export type RoomScheduleRowType = z.infer<typeof ROOM_SCHEDULE_ROWS_SCHEMA>;
export type ImportRowType = BulkImportFormField;

// Create a type for the validation responses
export type BulkSurveysWorkbookValidationResponse = {
  'Sheet Names': ValidationResponse<string[]>;
  'Setup Columns': ValidationResponse<string[]>;
  'Form Fields Columns': ValidationResponse<string[]>;
  'Room Schedule Columns': ValidationResponse<string[]>;
  [IMPORT_DATA.Setup]: ValidationResponse<SetupRowType>;
  [IMPORT_DATA.FormFields]: ValidationResponse<FormFieldRowType>;
  [IMPORT_DATA.RoomSchedule]: ValidationResponse<RoomScheduleRowType>;
  [IMPORT_DATA.Imports]: ValidationResponse<ImportRowType>;
};

export function validateWorkbook(
  workbook: XLSX.WorkBook,
): BulkSurveysWorkbookValidationResponse {
  const responses = {} as BulkSurveysWorkbookValidationResponse;

  function sortResponse<T>(response: ValidationResponse<T>) {
    const existing = responses[response.identifier] ?? {
      identifier: response.identifier,
      success: true,
      data: [],
      errors: [],
    };

    responses[response.identifier] = {
      identifier: response.identifier,
      success: existing.success && response.success,
      data: [...existing.data, ...response.data],
      errors: [...existing.errors, ...response.errors],
    };

    if (response.errors.length) {
      throw new Error('Validation Error');
    }
  }

  try {
    sortResponse(
      handleValidationResponse({
        response: SHEETS_SCHEMA.safeParse(workbook.SheetNames),
        identifier: 'Sheet Names',
      }),
    );

    const setupSheet = parseXLSXSheet({ sheet: workbook.Sheets.Setup });

    sortResponse(
      handleValidationResponse({
        response: SETUP_HEADERS_SCHEMA.safeParse(setupSheet.headers),
        identifier: 'Setup Columns',
        rowNumber: 1,
      }),
    );

    if (!setupSheet.rows.length) {
      sortResponse({
        success: false,
        identifier: IMPORT_DATA.Setup,
        data: [],
        errors: ['You must have at least one row of data in the Setup sheet'],
      });
    } else {
      setupSheet.rows.forEach((row, index) => {
        sortResponse(
          handleValidationResponse({
            response: SETUP_ROWS_SCHEMA.safeParse(row),
            identifier: IMPORT_DATA.Setup,
            rowNumber: index + 2,
          }),
        );
      });

      const duplicateSetupRows = setupSheet.rows
        ?.map((row) => {
          const id = `${row?.['Survey Name']} - ${row?.['Site Name']} - ${row?.['Building Name']}`;
          return id;
        })
        .filter((name, index, self) => {
          return name && self.indexOf(name) !== index;
        });

      if (duplicateSetupRows.length) {
        sortResponse({
          success: false,
          identifier: IMPORT_DATA.Setup,
          data: [],
          errors: [
            `Duplicate surveys found: ${duplicateSetupRows.join(
              ', ',
            )}. Surveys must be unique.`,
          ],
        });
      }
    }

    const formFieldsSheet = parseXLSXSheet({
      sheet: workbook.Sheets.FormFields,
    });

    sortResponse(
      handleValidationResponse({
        response: FORM_FIELDS_HEADERS_SCHEMA.safeParse(formFieldsSheet.headers),
        identifier: 'Form Fields Columns',
        rowNumber: 1,
      }),
    );

    const foundBaseFields = formFieldsSheet.rows.filter((row) => {
      const label = row?.['Label'] as string;
      return BASE_FORM_FIELDS.includes(label);
    });

    if (foundBaseFields.length < BASE_FORM_FIELDS.length) {
      sortResponse({
        success: false,
        identifier: IMPORT_DATA.FormFields,
        data: [],
        errors: [
          `You must have all of the fields: ${BASE_FORM_FIELDS.join(
            ', ',
          )} in the Form Fields sheet`,
        ],
      });
    } else {
      foundBaseFields.forEach((row) => {
        const label = row?.['Label'] as string;
        const type = row?.['Type'] as InputType;
        if (label === 'Description' && type !== 'Text') {
          sortResponse({
            success: false,
            identifier: IMPORT_DATA.FormFields,
            data: [],
            errors: [
              `The Description field must be of type Text, received ${type}`,
            ],
          });
        } else if (label === 'Quantity' && type !== 'Number') {
          sortResponse({
            success: false,
            identifier: IMPORT_DATA.FormFields,
            data: [],
            errors: [
              `The Quantity field must be of type Number, received ${type}`,
            ],
          });
        } else if (label === 'Remaining Life' && type !== 'Number') {
          sortResponse({
            success: false,
            identifier: IMPORT_DATA.FormFields,
            data: [],
            errors: [
              `The Remaining Life field must be of type Number, received ${type}`,
            ],
          });
        }
      });
    }

    formFieldsSheet.rows.forEach((row, index) => {
      sortResponse(
        handleValidationResponse({
          response: FORM_FIELDS_ROWS_SCHEMA.safeParse(row),
          identifier: IMPORT_DATA.FormFields,
          rowNumber: index + 2,
        }),
      );
    });

    const duplicateLabels = formFieldsSheet.rows
      ?.map((row) => row?.['Label'])
      .filter((label, index, self) => {
        return label && self.indexOf(label) !== index;
      });
    if (duplicateLabels.length) {
      sortResponse({
        success: false,
        identifier: IMPORT_DATA.FormFields,
        data: [],
        errors: [
          `Duplicate labels found: ${duplicateLabels.join(
            ', ',
          )}. Labels must be unique.`,
        ],
      });
    }

    const roomScheduleSheet = parseXLSXSheet({
      sheet: workbook.Sheets.RoomSchedule,
    });

    sortResponse(
      handleValidationResponse({
        response: ROOM_SCHEDULE_HEADERS_SCHEMA.safeParse(
          roomScheduleSheet.headers,
        ),
        identifier: 'Room Schedule Columns',
        rowNumber: 1,
      }),
    );

    roomScheduleSheet.rows?.forEach((row, index) => {
      sortResponse(
        handleValidationResponse({
          response: ROOM_SCHEDULE_ROWS_SCHEMA.safeParse(row),
          identifier: IMPORT_DATA.RoomSchedule,
          rowNumber: index + 2,
        }),
      );
    });

    const importsSheet = parseXLSXSheet({ sheet: workbook.Sheets.Imports });

    const baseSchema = z
      .object({
        'Survey Names': z.string().min(1).trim().optional(),
      })
      .merge(DEFAULT_IMPORT_COLUMNS_SCHEMA)
      .merge(
        z.object({
          [COLUMN_NAMES_MAP.UOM]: UOM_SCHEMA.optional().default('item'),
          [COLUMN_NAMES_MAP.COST_PER_UNIT]: COST_PER_UNIT_SCHEMA,
          [COLUMN_NAMES_MAP.UPLIFT]: UPLIFT_SCHEMA,
          [COLUMN_NAMES_MAP.DESCRIPTION]: z
            .string()
            .trim()
            .transform(removeNewLineCharacters)
            .optional(),
          [COLUMN_NAMES_MAP.QUANTITY]: z.number().nonnegative().optional(),
          [COLUMN_NAMES_MAP.REMAINING_LIFE]: z
            .number()
            .nonnegative()
            .optional(),
          [COLUMN_NAMES_MAP.EXPECTED_LIFE]: z.number().nonnegative().optional(),
        }),
      );

    let IMPORTS_SCHEMA: z.ZodObject<any> = baseSchema.extend({});

    formFieldsSheet.rows.forEach((row, index: number) => {
      const typedRow = row as BulkImportFormField;
      const zodType = inputTypeToZodType(typedRow.Type);

      IMPORTS_SCHEMA = IMPORTS_SCHEMA.merge(
        z.object({
          [typedRow.Label]: zodType?.optional(),
        }),
      );
    });

    importsSheet.rows.forEach((row, index) => {
      sortResponse(
        handleValidationResponse({
          response: IMPORTS_SCHEMA.safeParse(row),
          identifier: IMPORT_DATA.Imports,
          rowNumber: index + 2,
        }),
      );
    });

    return responses;
  } catch (e) {
    return responses;
  }
}
