import useSurvey from '../../../../hooks/useSurvey';
import { statuses } from '../../../../shared/utilities';
import Issue from './issue';
import Rooms from './rooms';

const Edit = () => {
  const survey = useSurvey();

  const issueMode =
    survey.status === statuses.UNASSIGNED ||
    survey.status === statuses.ISSUED ||
    survey.status === statuses.NOT_STARTED;

  return issueMode ? <Issue /> : <Rooms />;
};

export default Edit;
