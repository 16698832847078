import {
  Button,
  Stack,
  Step,
  StepButton,
  StepContent,
  Stepper,
} from '@mui/material';
import { useContext, useState } from 'react';

import { AppState } from '../../../../../../../config/store';
import { CompleteDialog } from '../../../shared/form/CompleteDialog';
import { CreateAssetReportsContext } from '../../..';
import { CreatePhotoScheduleReportData } from '../../../shared/types';
import FiltersStep from '../../../shared/form/FiltersStep';
import FrontPageStep from '../../../shared/form/FrontPageStep';
import InsidePageStep from '../../../shared/form/InsidePageStep';
import PreviewStep from './PreviewStep';
import ReportTypeStep from '../../../shared/form/ReportTypeStep';
import SettingsStep from './SettingsStep';
import { createPhotoScheduleReport } from '../../../shared/form/data-access';
import { useSelector } from 'react-redux';

const STARTING_STEP = 0;

const FORM_STEPS = [
  'Report Type',
  'Filters',
  'Front Page',
  'Inside Page',
  'Settings',
  'Preview',
];

export function PhotoScheduleReports() {
  const [activeStep, setActiveStep] = useState(STARTING_STEP);
  const client = useSelector((state: AppState) => state.firestore.data.client);
  const [showDialog, setShowDialog] = useState(false);

  const { changeReportType, reportType } = useContext(
    CreateAssetReportsContext,
  );

  const [values, setValues] = useState<Partial<CreatePhotoScheduleReportData>>({
    clientId: client.id ?? '',
    clientName: client.name ?? '',
    clientLogo: client.logoImage ?? '',
    reportType,
  });

  const handleClickStep = (index) => {
    setActiveStep(index);
  };

  const handleBack = (data?: Partial<CreatePhotoScheduleReportData>) => {
    setValues((prev) => ({ ...prev, ...data }));
    setActiveStep((prev) => prev - 1);
  };

  const handleNext = (data: Partial<CreatePhotoScheduleReportData>) => {
    setValues((prev) => ({ ...prev, ...data }));
    setActiveStep((prev) => prev + 1);
  };

  const handleFinish = () => {
    createPhotoScheduleReport(values as CreatePhotoScheduleReportData);
    setShowDialog(true);
  };

  return (
    <>
      <Stepper activeStep={activeStep} orientation="vertical" sx={{ p: 2 }}>
        {FORM_STEPS.map((label, index) => (
          <Step key={label}>
            <Stack direction="row" spacing={2} alignItems="center">
              <StepButton onClick={() => handleClickStep(index)}>
                {label}
              </StepButton>
            </Stack>
            <StepContent>
              {(index === 0 && (
                <ReportTypeStep
                  handleNext={handleNext}
                  onChange={changeReportType}
                  reportType={reportType}
                />
              )) ||
                (index === 1 && (
                  <FiltersStep
                    clientId={client.id}
                    defaultValues={values}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    requiredFilters={{ siteId: true, buildingId: true }}
                  />
                )) ||
                (index === 2 && (
                  <FrontPageStep
                    defaultValues={values}
                    handleBack={handleBack}
                    handleNext={handleNext}
                  />
                )) ||
                (index === 3 && (
                  <InsidePageStep
                    defaultValues={values}
                    handleBack={handleBack}
                    handleNext={handleNext}
                  />
                )) ||
                (index === 4 && (
                  <SettingsStep
                    defaultValues={values}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    assetData={values.assetData}
                  />
                )) ||
                (index === 5 && (
                  <PreviewStep
                    data={values as CreatePhotoScheduleReportData}
                    handleBack={handleBack}
                    handleNext={handleFinish}
                  />
                ))}
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <CompleteDialog open={showDialog} onClose={() => setShowDialog(false)} />
    </>
  );
}

export default PhotoScheduleReports;
