import { Box } from '@mui/material';
import ImportColumnMatcher, {
  MappedHeaders,
} from '../../../../../../components/ImportColumnMatcher';
import { getRandomID } from '../../../../../../shared/utilities';
import {
  COLUMN_NAMES_MAP,
  parseDataWithSchema,
} from '../../../../../../shared/validation';

import { ZodIssue } from 'zod';
import StepGuide from '../../../../../../components/StepGuide';
import { UNSPECIFIED_LABEL } from '../../../../../../config/constants';
import { useImportStepData } from '../../../../../../state/survey-form';
import { RoomProps } from '../../../../../../types/room';

type ColumnMappingStepProps = {
  stepNumber: number;

  onMappingResult: ({
    matches,
    mappedRows,
    issues,
    rooms,
  }: {
    matches?: MappedHeaders;
    mappedRows?: any;
    issues?: ZodIssue[];
    rooms?: Partial<RoomProps>[];
  }) => void;
};

export default function ColumnMappingStep({
  stepNumber,
  onMappingResult,
}: ColumnMappingStepProps) {
  const { importData, schema, columns } = useImportStepData();

  const parseRooms = (rows) => {
    if (!rows) {
      return [];
    }
    const roomsDelimitedSet = new Set<string>();
    const DELIMITER = '___';

    rows.forEach((row) => {
      const uid = `${row['Room Name'] || UNSPECIFIED_LABEL}${DELIMITER}${
        row['Floor'] || UNSPECIFIED_LABEL
      }`;
      roomsDelimitedSet.add(uid);
    });

    return Array.from(roomsDelimitedSet).map((rp) => {
      const split = rp.split(DELIMITER);
      const [name, floor] = split;
      // find the first row that matches the room name so can pull the room dimensions from if (if set)
      const matchingRow = rows.find((r) => {
        return r['Room Name'] === name;
      });
      const nameTrimmed = String(name).trim();
      const floorTrimmed = String(floor).trim();

      return {
        name: nameTrimmed,
        floor: floorTrimmed,
        areaFloor: Number(matchingRow[COLUMN_NAMES_MAP.ROOM_AREA_FLOOR]) ?? 0,
        areaWall: Number(matchingRow[COLUMN_NAMES_MAP.ROOM_AREA_WALL]) ?? 0,
        deleted: 0,
        imported: true,
        id: getRandomID(),
      };
    });
  };

  const onConfirmMapping = (matches: MappedHeaders) => {
    const HEADERS = Object.keys(matches);
    const mappedRows = importData?.initialRows.map((row) => {
      return HEADERS.reduce((acc, value) => {
        return { ...acc, [value]: row[matches[value]] };
      }, {});
    });

    const schemaCheck = parseDataWithSchema({
      data: mappedRows,
      schema,
    });

    if (!schemaCheck.success) {
      return onMappingResult({ issues: schemaCheck.error.issues });
    }
    const rooms = parseRooms(schemaCheck.data);
    return onMappingResult({ matches, mappedRows, rooms });
  };

  return importData?.initialHeaders ? (
    <Box mt={8}>
      <Box my={2} display="flex" flexDirection="column" alignItems="center">
        <StepGuide
          stepNumber={stepNumber}
          text="Match your import data to the correct columns, then validate it."
        />

        <ImportColumnMatcher
          destinationColumns={columns}
          sourceColumns={importData.initialHeaders}
          mappedHeaders={importData.mappedHeaders}
          onConfirmMapping={onConfirmMapping}
        />
      </Box>
    </Box>
  ) : null;
}
