import { Box, Typography } from '@mui/material';

import { colours } from '../../../../config/theme';

import COUNTRY_LIST from '../../../../config/country-codes.json';

type CountryCodeEntry = {
  name: string;
  countryCode: string;
  codeNumeric: string;
};

export default function CountryCodesTable() {
  return (
    <Box>
      <Box display="flex" mb={2} justifyContent="space-between">
        <Box pr={2}>
          <Typography color="secondary" variant="body1" fontWeight={700}>
            Country
          </Typography>
        </Box>
        <Box>
          <Typography color="secondary" variant="body1" fontWeight={700}>
            Code
          </Typography>
        </Box>
      </Box>
      {COUNTRY_LIST.map((country: CountryCodeEntry) => {
        return (
          <Box
            key={country.countryCode}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            py={0.5}
            borderBottom={`1px solid ${colours.grey5}`}
          >
            <Box pr={2}>
              <Typography color="secondary" variant="body1">
                {country.name}
              </Typography>
            </Box>
            <Box>
              <Typography color="secondary" variant="body1">
                {country.countryCode}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
