import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useEffect, useMemo, useRef, useState } from 'react';

import { ALL_ROLES } from '../../config/roles';
import AssetDataExportNew from './reporting/create';
import AssetDataImport from './asset-data-import';
import AssetLists from './asset-lists';
import ClientHeader from '../../components/ClientHeader';
import CreateAssetReports from './reporting/create';
import Dashboard from './dashboard';
import ErrorBoundary from '../../components/ErrorBoundary';
import NotFound from '../not-found';
import { ROUTES_MAP } from '../../config/routes';
import RouteGuard from '../../components/RouteGuard';
import Settings from './settings';
import Sites from './sites';
import Surveys from './surveys';
import Tools from './tools';
import Users from './users';
import { ViewAssetReports } from './reporting/view';
import { useSelector } from 'react-redux';

const ClientPortal = () => {
  const ids = useSelector((state) => state.params.ids);
  const client = useSelector((state) => state.firestore.data.client);
  const noClientFound = isLoaded(client) && isEmpty(client);
  const history = useHistory();

  const prevIds = useRef();

  const [queries, setQueries] = useState([]);

  useEffect(() => {
    if (noClientFound) {
      history.replace(ROUTES_MAP.NOT_FOUND.path);
    }
  }, [noClientFound]);

  const currentPortalUserRole = useSelector(
    (state) => state.firestore.data.currentPortalUserRole,
  );

  useEffect(() => {
    const arr = [];

    if (ids.clientId) {
      arr.push(
        {
          collection: 'clients',
          doc: ids.clientId,
          storeAs: 'client',
        },
        {
          collection: 'clients',
          doc: ids.clientId,
          subcollections: [
            { collection: 'sites', where: ['deleted', '==', 0] },
          ],
          storeAs: 'sites',
          orderBy: ['name', 'asc'],
        },
        {
          collection: 'clients',
          doc: ids.clientId,
          subcollections: [{ collection: 'surveys-meta' }],
          storeAs: `surveys`,
        },
      );
    }

    if (ids.floor && ids.surveyId) {
      arr.push({
        collection: 'clients',
        doc: ids.clientId,
        subcollections: [
          { collection: 'surveys', doc: ids.surveyId },
          { collection: 'rooms' },
        ],
        where: [
          ['floor', '==', ids.floor],
          ['deleted', '==', 0],
        ],
        orderBy: [['lastUpdated.time', 'desc']],
        storeAs: 'rooms',
      });
    }

    if (ids.roomId) {
      arr.push(
        {
          collection: 'clients',
          doc: ids.clientId,
          subcollections: [
            { collection: 'surveys', doc: ids.surveyId },
            { collection: 'rooms', doc: ids.roomId },
            { collection: 'assets' },
          ],
          where: ['deleted', '==', 0],
          orderBy: ['lastUpdated.time', 'desc'],
          storeAs: 'assets',
        },
        {
          collection: 'clients',
          doc: ids.clientId,
          subcollections: [
            { collection: 'surveys', doc: ids.surveyId },
            { collection: 'rooms', doc: ids.roomId },
          ],
          storeAs: 'room',
        },
      );
    }
    if (ids.userId) {
      arr.push(
        {
          collection: 'users',
          doc: `${ids.userId}`,
          storeAs: 'user',
        },
        {
          collection: 'roles',
          where: [['clientId', '==', ids.clientId]],
          storeAs: 'roles',
        },
      );
    }

    setQueries(arr);
    prevIds.current = ids;
  }, [ids]);

  useFirestoreConnect(queries);

  return (
    <>
      {!noClientFound && <ClientHeader />}
      <ErrorBoundary>
        <Switch>
          <RouteGuard {...ROUTES_MAP.SITES}>
            <Sites />
          </RouteGuard>
          <RouteGuard {...ROUTES_MAP.SURVEYS}>
            <Surveys />
          </RouteGuard>
          <RouteGuard {...ROUTES_MAP.ASSET_LISTS}>
            <AssetLists />
          </RouteGuard>
          <RouteGuard {...ROUTES_MAP.REPORTING_CREATE}>
            <CreateAssetReports />
          </RouteGuard>
          <RouteGuard {...ROUTES_MAP.REPORTING_VIEW}>
            <ViewAssetReports />
          </RouteGuard>
          <RouteGuard {...ROUTES_MAP.ASSET_DATA_IMPORT}>
            <AssetDataImport />
          </RouteGuard>
          <RouteGuard {...ROUTES_MAP.USERS}>
            <Users />
          </RouteGuard>
          <RouteGuard {...ROUTES_MAP.SETTINGS}>
            <Settings />
          </RouteGuard>
          <RouteGuard {...ROUTES_MAP.TOOLS}>
            <Tools />
          </RouteGuard>
          <Route {...ROUTES_MAP.NOT_FOUND}>
            <NotFound />
          </Route>
          <RouteGuard {...ROUTES_MAP.DASHBOARD}>
            <Dashboard />
          </RouteGuard>
          <Route path="*">
            <Redirect to={ROUTES_MAP.NOT_FOUND.path} />
          </Route>
        </Switch>
      </ErrorBoundary>
    </>
  );
};

export default ClientPortal;
