import 'leaflet/dist/leaflet.css';

import { useDispatch, useSelector } from 'react-redux';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../../../shared/helpers';
import {
  onBuildingSubmitted,
  onSelectedBuildingDelete,
  onSiteEditSubmitted,
} from '../../../../state/site';

import { Grid } from '@mui/material';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router-dom';
import TitleBar from '../../../../components/MainContent/TitleBar';
import SiteAndBuildingsForm from '../components/SiteAndBuildingsForm';

const Edit = () => {
  const dispatch = useDispatch();
  const { clientId, siteId } = useParams();

  useFirestoreConnect([
    {
      collection: 'clients',
      doc: clientId,
      subcollections: [
        { collection: 'sites', doc: siteId },
        {
          collection: 'buildings',
          where: ['deleted', '==', 0],
          orderBy: ['name', 'asc'],
        },
      ],
      storeAs: 'buildings',
    },
    {
      collection: 'clients',
      doc: clientId,
      subcollections: [{ collection: 'sites', doc: siteId }],
      storeAs: 'site',
    },
  ]);

  const site = useSelector(
    (state) => state.firestore.data.site || EMPTY_OBJECT,
  );
  const buildings = useSelector(
    (state) => state.firestore.ordered.buildings || EMPTY_ARRAY,
  );

  const onSiteSubmit = (siteData) => {
    dispatch(onSiteEditSubmitted({ site: siteData, siteId }));
  };

  const onBuildingSubmit = (building) => {
    dispatch(onBuildingSubmitted({ building, siteId }));
  };

  const onBuildingDelete = (building) => {
    dispatch(onSelectedBuildingDelete({ building, siteId }));
  };

  return (
    <Grid container spacing={2}>
      <TitleBar title="Edit Site & Buildings" client />

      <SiteAndBuildingsForm
        site={site}
        buildings={buildings}
        onSiteSubmit={onSiteSubmit}
        onBuildingSubmit={onBuildingSubmit}
        onBuildingDelete={onBuildingDelete}
      />
    </Grid>
  );
};

export default Edit;
