import { getFirebase, isEmpty, isLoaded } from 'react-redux-firebase';

import { Link } from '@mui/material';
import Compressor from 'compressorjs';
import ImageBlobReduce from 'image-blob-reduce';
import { customAlphabet } from 'nanoid';
import XLSX from 'xlsx-js-style';
import { colours } from '../config/theme';
import { ReportType } from '../routes/client-portal/reporting/create/shared/types';
import { ImageProps } from '../types/image';
import { RoomSchedule } from '../types/survey';
// for some reason this isnt exported from redux-firestore but it still works
// @ts-ignore
import heic2any from 'heic2any';
//@ts-ignore - this is a private function but is allowed to be imported
import { getSnapshotByObject } from 'redux-firestore';
import { UNSPECIFIED_LABEL } from '../config/constants';
import COUNTRY_LIST from '../config/country-codes.json';
import { Floorplan } from '../types/floorplan';
import { recordError } from './logTools';

const emailRegex = /^[A-Z0-9'._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/gi;
// const passwordRegex = /^.{8,}$/i;
// at least one uppercase, one lowercase, one number and be at least 12 length
const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{12,}$/;

const numberRegex = /^[\d]*$/;
const companyNumberRegex = /^[\d]{8}$/;
const clientIdRegex = /^[a-z0-9-]+$/;

const getRandomNumberBetween = (from: number, to: number) => {
  return Math.floor(Math.random() * to) + from;
};

function isNumeric(value: any) {
  return !isNaN(parseFloat(value)) && isFinite(value);
}

const getRandomHyphenatedStringFromArray = (
  wordsArray: string[],
  multiplier: number = 2,
) => {
  const getRandomFromArray = (array) => {
    return array[getRandomNumberBetween(0, array.length)];
  };
  const randomised: string[] = [];
  const numResults = Math.round(wordsArray.length * multiplier);
  for (let index = 0; index < numResults; index += 1) {
    randomised.push(getRandomFromArray(wordsArray));
  }
  return randomised.join('-');
};

function parseXLSXSheet<T>({
  sheet,
  columnNames,
  defaultValue,
  rejectBlanks = false,
  removeNewLines = false,
}: {
  sheet: XLSX.WorkSheet;
  columnNames?: string[];
  defaultValue?: any;
  rejectBlanks?: boolean;
  removeNewLines?: boolean;
}): { rows: T[]; headers: string[]; types: string[] } {
  let rows: T[] = [];

  type DataRow = [];

  const data = XLSX.utils.sheet_to_json<DataRow>(sheet, {
    header: 1,
    defval: defaultValue,
  });

  const [headers, types = []] = data;

  rows = XLSX.utils.sheet_to_json<T>(sheet, {
    header: columnNames || headers,
    range: 1,
    defval: defaultValue,
  });

  rows = rows
    .filter((row: any) => {
      if (!rejectBlanks) {
        return row;
      }
      // reject rows that have more than a quarter of the columns blank (or 2 columns if its a very small import)
      // this is to avoid any whitespace characters being interpreted as data and causing issues
      const usedColumns = Object.values(row)?.length;
      return usedColumns > Math.max(headers.length / 4, 2);
    })
    .map((row: any) => {
      const newRow: any = {};
      Object.keys(row).forEach((prop) => {
        try {
          if (!removeNewLines) {
            newRow[prop] =
              typeof row[prop] === 'string' ? row[prop].trim() : row[prop];
          }
          //remove new line characters from the property name
          const propCleaned = removeNewLineCharacters(prop).trim();
          if (typeof row[prop] === 'string') {
            //remove new line characters from the value
            newRow[propCleaned] = removeNewLineCharacters(row[prop].trim());
            //handle if the string is actually a number
            if (isNumeric(newRow[propCleaned])) {
              newRow[propCleaned] = parseFloat(newRow[propCleaned]);
            }
          } else {
            newRow[propCleaned] = row[prop];
          }
        } catch (err) {
          console.error(err);
        }
      });
      return newRow;
    });

  const rowTypes = types?.map((t) => typeof t);

  return { rows, headers, types: rowTypes };
}

async function parseXLSX<T>(
  file: ArrayBuffer,
  columnNames?: string[],
  defaultValue?: any,
  rejectBlanks: boolean = true,
  removeNewLines: boolean = false,
): Promise<{ rows: T[]; headers: string[]; types: string[] }> {
  return new Promise((resolve, reject) => {
    try {
      const workbook = XLSX.read(file, { type: 'binary' });

      workbook.SheetNames.forEach((sheetName, index) => {
        if (index === 0) {
          const { rows, headers, types } = parseXLSXSheet<T>({
            sheet: workbook.Sheets[sheetName],
            columnNames,
            defaultValue,
            rejectBlanks,
            removeNewLines,
          });
          if (rows.length === 0) {
            return window.alert("Your file doesn't contain any data");
          }
          resolve({ rows, headers, types });
        }
      });
    } catch (error) {
      reject(error);
    }
  });
}

const getRandomID = () => {
  return customAlphabet(
    '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
    25,
  )();
};

const getBlobFile = async (blobUrl) => {
  const response = await fetch(blobUrl);
  if (!response.ok) {
    throw new Error(`Failed to fetch Blob: ${blobUrl}`);
  }
  return response.blob();
};

const removeDuplicatesFromArray = (array) => {
  return Array.from(new Set(array));
};

const isSameFloorplan = (file1: Floorplan, file2: Floorplan) => {
  if (!file1 || !file1.lastModified || !file2 || !file2.lastModified) {
    return false;
  }
  const isSame =
    file1.lastModified === file2.lastModified &&
    file1.fileName === file2.fileName;
  return isSame;
};

const getConditionColour = (condition: string) => {
  switch (condition) {
    case 'A':
      return colours.green3;
    case 'B':
      return colours.yellow;
    case 'C':
      return colours.orange;
    case 'D':
      return colours.red;
    case 'DX':
      return colours.black;
    default:
      return colours.black;
  }
};

const statuses = {
  UNASSIGNED: 'unassigned',
  ISSUED: 'issued',
  NOT_STARTED: 'notStarted',
  IN_PROGRESS: 'inProgress',
  COMPLETED: 'completed',
  NO_ACCESS: 'noAccess',
};

// HELPER TO PRETTY PRINT STATUS STRING
const statusesToPretty = {
  [statuses.UNASSIGNED]: 'Unassigned',
  [statuses.ISSUED]: 'Issued',
  [statuses.NOT_STARTED]: 'Not Started',
  [statuses.IN_PROGRESS]: 'In Progress',
  [statuses.COMPLETED]: 'Complete',
  [statuses.NO_ACCESS]: 'No Access',
};

const reportTypesToPretty = {
  [ReportType.ASSET_DATA]: 'Asset Data',
  [ReportType.LIFE_CYCLE]: 'Life Cycle',
  [ReportType.PHOTO_SCHEDULE]: 'Photo Schedule',
  [ReportType.OPERATIONAL_CARBON]: 'Operational Carbon',
  [ReportType.EMBODIED_CARBON]: 'Embodied Carbon',
  [ReportType.OPERATIONAL_ENERGY]: 'Operational Energy',
  [ReportType.MAINTENANCE_PLANNER]: 'Maintenance Planner',
};

const reportTypeToString = (reportType: string) =>
  reportTypesToPretty[reportType] || 'Asset Data';
const statusToString = (status) => statusesToPretty[status] || 'Not Started';

const getStatusStats = (list) => {
  let completed = 0;
  let inProgress = 0;
  let notStarted = 0;
  let issued = 0;
  let unassigned = 0;
  let noAccess = 0;
  let unknown = 0;
  list.forEach((listItem) => {
    switch (listItem.status) {
      case statuses.UNASSIGNED:
        unassigned += 1;
        break;
      case statuses.ISSUED:
        issued += 1;
        break;
      case statuses.NOT_STARTED:
        notStarted += 1;
        break;
      case statuses.IN_PROGRESS:
        inProgress += 1;
        break;
      case statuses.COMPLETED:
        completed += 1;
        break;
      case statuses.NO_ACCESS:
        noAccess += 1;
        break;
      default:
        unknown += 1;
        break;
    }
  });
  return {
    unassigned,
    issued,
    notStarted,
    inProgress,
    completed,
    noAccess,
    unknown,
  };
};

const getStatusColour = (status) => {
  switch (status) {
    case statuses.IN_PROGRESS:
      return colours.yellow;
    case statuses.COMPLETED:
      return colours.brand1;
    case statuses.NO_ACCESS:
      return colours.red;
    default:
      return 'inherit';
  }
};

const documentIdMatch = (id, document) => {
  const snap = getSnapshotByObject(document) || {};
  return (
    id && snap.id && isLoaded(document) && !isEmpty(document) && id === snap.id
  );
};

const dateFromTimeStamp = (timeStamp) =>
  new Date(timeStamp).toLocaleDateString('en-GB', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: '2-digit',
  });

const timeFromTimeStamp = (timeStamp) =>
  new Date(timeStamp).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });

const handlePlural = (num, word, includeNum) => {
  let output;
  if (includeNum) {
    output = `${num || 0} ${word}`;
  } else {
    output = `${word}`;
  }
  return num === 1 && word.length > 1 ? output.slice(0, -1) : output;
};

const getPrevUrlSection = (numSections) => {
  return window.location.pathname.split('/').slice(0, -numSections).join('/');
};

const getImageDimensions = (
  uri: string,
): Promise<{ width: number; height: number }> =>
  new Promise((resolve, reject) => {
    const img = new Image();

    // the following handler will fire after a successful loading of the image
    img.onload = () => {
      const { naturalWidth: width, naturalHeight: height } = img;
      resolve({ width, height });
    };

    // and this handler will fire if there was an error with the image (like if it's not really an image or a corrupted one)
    img.onerror = (e) => {
      console.error(e);
      reject(new Error(`There was some problem with the image`));
    };

    img.src = uri;
  });

const isLocalImage = (fileUrlString) => {
  return fileUrlString?.indexOf('file://') === 0;
};

const isBlobImage = (fileUrlString) => {
  return fileUrlString?.indexOf('data:image') === 0;
};

export const NOT_GOT_IMAGE_URL = `${window.location.origin}/images/awaiting-image.png`;

const getMainImage = (assetOrPartial) => {
  return assetOrPartial?.images?.[0] ? assetOrPartial.images[0].uri : '';
};

const handleLocalImage = (imageURL) => {
  if (imageURL && isLocalImage(imageURL)) {
    return NOT_GOT_IMAGE_URL;
  }
  return imageURL;
};

const removeFalsiesFromObject = (obj) => {
  return Object.keys(obj)
    .filter((k) => !!obj[k])
    .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});
};

function removeUndefinedFromObject<T>(obj: T) {
  return Object.keys(obj as object)
    .filter((k) => typeof obj[k] !== 'undefined')
    .reduce((a, k) => ({ ...a, [k]: obj[k] }), {}) as T;
}

const isViewingClientPortal = (clientId) => {
  return clientId && window.location.pathname.includes(clientId);
};

const isViewingProdServer = () => {
  return (
    window.location.href.indexOf('https://portal.asseticom.co.uk') === 0 ||
    import.meta.env.VITE_CCC_ENVIRONMENT === 'production'
  );
};

const isViewingLocalServer = () => {
  return (
    window.location.hostname === 'localhost' ||
    (window.location.hostname === '127.0.0.1' &&
      import.meta.env.VITE_CCC_ENVIRONMENT === 'dev')
  );
};

const isUrl = (str: string) => {
  return typeof str === 'string' && str.startsWith('http');
};

const getStorageForPath = (path) => {
  // @ts-ignore
  const storage = getFirebase().app().storage(path);
  if (isViewingLocalServer()) {
    console.log('use emulator');
    storage.useEmulator('localhost', 9199);
  }
  return storage;
};

// The adapter address an issue when components expose ref name differently (eg. innerRef, inputRef, etc.).
const refAdapter = (registerReturn, refName) => {
  const { ref, ...rest } = registerReturn;
  return {
    [refName]: ref,
    ...rest,
  };
};

const getColumnFormat = (value, fallback) => {
  if (isUrl(value)) {
    return (
      <Link
        href={value}
        target="_blank"
        variant="caption"
        color="secondary"
        underline="hover"
      >
        Open
      </Link>
    );
  }
  if (typeof value === 'undefined' || value === null || value === '') {
    return fallback;
  }
  return value;
};

const shortenURL = async (url: string) => {
  if (isViewingLocalServer()) {
    return url;
  }
  const prefix = isViewingProdServer() ? 's' : 'ss';
  try {
    const encodedURL = encodeURIComponent(url);
    const result = await fetch(
      `https://shorten.asseticom.co.uk?input=${encodedURL}&prefix=${prefix}`,
    );
    if (result.ok) {
      return await result.text();
    } else {
      console.error('could not shorten, using original', url);
      return url;
    }
  } catch (error) {
    console.error(error);
    return url;
  }
};

const getSafeTextFromText = (label) => {
  return label.replace(/\s/g, '_').replace(/[^\w\d_]/g, '');
};

const getTextFromSafeText = (safeLabel) => {
  return safeLabel.replace(/_/g, ' ');
};

function replaceLeadingDigitsWithLetters(str) {
  return str.replace(/^\d+/g, (match) => {
    const alphabet = 'abcdefghijklmnopqrstuvwxyz';
    let result = '';
    for (let i = 0; i < match.length; i++) {
      const digit = parseInt(match[i], 10);
      result += alphabet[digit];
    }
    return result;
  });
}

/**
 * Groups all items in an array of objects `T` where the value of property `K` is the same
 * @param array Items to group
 * @param key Key of `T` to group by
 * @param exclude whether to exclude the Key property from the result
 */
function groupArrayByProp(array, key) {
  const map = new Map();
  array.forEach((item) => {
    const itemKey = item[key];
    if (!map.has(itemKey)) {
      map.set(
        itemKey,
        array.filter((i) => i[key] === item[key]),
      );
    }
  });
  return map;
}

function sortArrayByProp<T>(array: T[], prop: string): T[] {
  if (!array.length || !prop) {
    return [];
  }
  return array.sort((a, b) => {
    if (a[prop] < b[prop]) {
      return -1;
    }
    if (a[prop] > b[prop]) {
      return 1;
    }
    return 0;
  });
}

const removeNewLineCharacters = (s) => {
  if (typeof s !== 'string') {
    return s;
  }
  return s?.replace(/(\r\n|\n|\r)/gm, '');
};

function getBarcodeFormFieldFromSurvey(survey) {
  return survey?.formFields?.find((f) => f.type === 'Barcode');
}

function getAllCombinedRoomsForSurvey(
  survey,
  usedRooms,
  floor?: string,
  sortFunction?: (a: any, b: any) => number | undefined,
) {
  const roomIds = usedRooms?.map((r) => r.id);

  return (
    survey?.roomSchedule?.data
      .filter((r) => r.deleted === 0 && (floor ? r.floor === floor : true))
      .map((room) => {
        let roomToUse = { ...room, lastUpdated: { time: 0 } };
        // if room exists in firestore collection data it must have been started
        if (roomIds?.includes(room.id)) {
          roomToUse = usedRooms?.find((r) => r.id === room.id);
        }
        // otherwise use the template from room list
        return roomToUse;
      })
      // sort by last updated time (most recent first)
      .sort(sortFunction) || []
  );
}

async function autoSizeWorksheetWidth(worksheet) {
  const data: { [prop: string]: any }[] = XLSX?.utils.sheet_to_json(worksheet);
  const columnWidths: { wch: number }[] = [];

  if (!data || data.length === 0) {
    return worksheet;
  }

  const keys = Object.keys(data[0]);
  keys.forEach((key) => {
    const other = data.map((obj) => {
      return obj[key] ? String(obj[key]).length : 0;
    });
    const longest = Math.max(key ? String(key).length : 0, ...other);

    columnWidths.push({
      wch: Math.max(key ? String(key).length : 0, longest),
    });
  });
  worksheet['!cols'] = columnWidths;

  return worksheet;
}

const sortTypes = {
  DESC: 'descending',
  ASC: 'ascending',
};

const readURLAsBinary = async (file: File) => {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    if (!file) {
      reject(new Error('No file provided'));
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target?.result as ArrayBuffer);
    };
    reader.onerror = (e) => {
      reject(new Error('Error reading file'));
    };
    reader.readAsArrayBuffer(file);
  });
};

const readURLAsData = async (file: File) => {
  return new Promise<string>((resolve, reject) => {
    if (!file) {
      reject(new Error('No file provided'));
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target?.result as string);
    };
    reader.onerror = (e) => {
      reject(new Error('Error reading file'));
    };
    reader.readAsDataURL(file);
  });
};

const fileNameAndExtensionFromPath = (path: string) => {
  const separatorIndex = path.lastIndexOf('.');
  const longname = path.substring(0, separatorIndex);
  const name = longname.split('/').pop();
  const extension = path.substring(separatorIndex + 1);
  return {
    name,
    extension,
  };
};

const trimWhitespaceFromRows = async (ws: XLSX.WorkSheet) => {
  const range = ws['!ref'] ? XLSX.utils.decode_range(ws['!ref']) : null;
  if (!range) {
    return ws;
  }
  for (let R = range.s.r; R <= range.e.r; ++R) {
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const coord = XLSX.utils.encode_cell({ r: R, c: C }),
        cell = ws[coord];
      if (!cell || !cell.v) continue;
      // clean up raw value of string cells
      if (cell.t == 's') cell.v = cell.v.trim();
      // clean up formatted text
      if (cell.w) cell.w = cell.w.trim();
    }
  }
  return ws;
};

function stringToHash(inputString: string) {
  const hc = '0123456789abcdef';
  function rh(n) {
    let j,
      s = '';
    for (j = 0; j <= 3; j++)
      s +=
        hc.charAt((n >> (j * 8 + 4)) & 0x0f) + hc.charAt((n >> (j * 8)) & 0x0f);
    return s;
  }
  function ad(x, y) {
    const l = (x & 0xffff) + (y & 0xffff);
    const m = (x >> 16) + (y >> 16) + (l >> 16);
    return (m << 16) | (l & 0xffff);
  }
  function rl(n, c) {
    return (n << c) | (n >>> (32 - c));
  }
  function cm(q, a, b, x, s, t) {
    return ad(rl(ad(ad(a, q), ad(x, t)), s), b);
  }
  function ff(a, b, c, d, x, s, t) {
    return cm((b & c) | (~b & d), a, b, x, s, t);
  }
  function gg(a, b, c, d, x, s, t) {
    return cm((b & d) | (c & ~d), a, b, x, s, t);
  }
  function hh(a, b, c, d, x, s, t) {
    return cm(b ^ c ^ d, a, b, x, s, t);
  }
  function ii(a, b, c, d, x, s, t) {
    return cm(c ^ (b | ~d), a, b, x, s, t);
  }
  function sb(x) {
    let i;
    const nblk = ((x.length + 8) >> 6) + 1;
    const blks = new Array(nblk * 16);
    for (i = 0; i < nblk * 16; i++) blks[i] = 0;
    for (i = 0; i < x.length; i++)
      blks[i >> 2] |= x.charCodeAt(i) << ((i % 4) * 8);
    blks[i >> 2] |= 0x80 << ((i % 4) * 8);
    blks[nblk * 16 - 2] = x.length * 8;
    return blks;
  }
  const x = sb('' + inputString);
  let i,
    a = 1732584193,
    b = -271733879,
    c = -1732584194,
    d = 271733878,
    olda,
    oldb,
    oldc,
    oldd;
  for (i = 0; i < x.length; i += 16) {
    olda = a;
    oldb = b;
    oldc = c;
    oldd = d;
    a = ff(a, b, c, d, x[i + 0], 7, -680876936);
    d = ff(d, a, b, c, x[i + 1], 12, -389564586);
    c = ff(c, d, a, b, x[i + 2], 17, 606105819);
    b = ff(b, c, d, a, x[i + 3], 22, -1044525330);
    a = ff(a, b, c, d, x[i + 4], 7, -176418897);
    d = ff(d, a, b, c, x[i + 5], 12, 1200080426);
    c = ff(c, d, a, b, x[i + 6], 17, -1473231341);
    b = ff(b, c, d, a, x[i + 7], 22, -45705983);
    a = ff(a, b, c, d, x[i + 8], 7, 1770035416);
    d = ff(d, a, b, c, x[i + 9], 12, -1958414417);
    c = ff(c, d, a, b, x[i + 10], 17, -42063);
    b = ff(b, c, d, a, x[i + 11], 22, -1990404162);
    a = ff(a, b, c, d, x[i + 12], 7, 1804603682);
    d = ff(d, a, b, c, x[i + 13], 12, -40341101);
    c = ff(c, d, a, b, x[i + 14], 17, -1502002290);
    b = ff(b, c, d, a, x[i + 15], 22, 1236535329);
    a = gg(a, b, c, d, x[i + 1], 5, -165796510);
    d = gg(d, a, b, c, x[i + 6], 9, -1069501632);
    c = gg(c, d, a, b, x[i + 11], 14, 643717713);
    b = gg(b, c, d, a, x[i + 0], 20, -373897302);
    a = gg(a, b, c, d, x[i + 5], 5, -701558691);
    d = gg(d, a, b, c, x[i + 10], 9, 38016083);
    c = gg(c, d, a, b, x[i + 15], 14, -660478335);
    b = gg(b, c, d, a, x[i + 4], 20, -405537848);
    a = gg(a, b, c, d, x[i + 9], 5, 568446438);
    d = gg(d, a, b, c, x[i + 14], 9, -1019803690);
    c = gg(c, d, a, b, x[i + 3], 14, -187363961);
    b = gg(b, c, d, a, x[i + 8], 20, 1163531501);
    a = gg(a, b, c, d, x[i + 13], 5, -1444681467);
    d = gg(d, a, b, c, x[i + 2], 9, -51403784);
    c = gg(c, d, a, b, x[i + 7], 14, 1735328473);
    b = gg(b, c, d, a, x[i + 12], 20, -1926607734);
    a = hh(a, b, c, d, x[i + 5], 4, -378558);
    d = hh(d, a, b, c, x[i + 8], 11, -2022574463);
    c = hh(c, d, a, b, x[i + 11], 16, 1839030562);
    b = hh(b, c, d, a, x[i + 14], 23, -35309556);
    a = hh(a, b, c, d, x[i + 1], 4, -1530992060);
    d = hh(d, a, b, c, x[i + 4], 11, 1272893353);
    c = hh(c, d, a, b, x[i + 7], 16, -155497632);
    b = hh(b, c, d, a, x[i + 10], 23, -1094730640);
    a = hh(a, b, c, d, x[i + 13], 4, 681279174);
    d = hh(d, a, b, c, x[i + 0], 11, -358537222);
    c = hh(c, d, a, b, x[i + 3], 16, -722521979);
    b = hh(b, c, d, a, x[i + 6], 23, 76029189);
    a = hh(a, b, c, d, x[i + 9], 4, -640364487);
    d = hh(d, a, b, c, x[i + 12], 11, -421815835);
    c = hh(c, d, a, b, x[i + 15], 16, 530742520);
    b = hh(b, c, d, a, x[i + 2], 23, -995338651);
    a = ii(a, b, c, d, x[i + 0], 6, -198630844);
    d = ii(d, a, b, c, x[i + 7], 10, 1126891415);
    c = ii(c, d, a, b, x[i + 14], 15, -1416354905);
    b = ii(b, c, d, a, x[i + 5], 21, -57434055);
    a = ii(a, b, c, d, x[i + 12], 6, 1700485571);
    d = ii(d, a, b, c, x[i + 3], 10, -1894986606);
    c = ii(c, d, a, b, x[i + 10], 15, -1051523);
    b = ii(b, c, d, a, x[i + 1], 21, -2054922799);
    a = ii(a, b, c, d, x[i + 8], 6, 1873313359);
    d = ii(d, a, b, c, x[i + 15], 10, -30611744);
    c = ii(c, d, a, b, x[i + 6], 15, -1560198380);
    b = ii(b, c, d, a, x[i + 13], 21, 1309151649);
    a = ii(a, b, c, d, x[i + 4], 6, -145523070);
    d = ii(d, a, b, c, x[i + 11], 10, -1120210379);
    c = ii(c, d, a, b, x[i + 2], 15, 718787259);
    b = ii(b, c, d, a, x[i + 9], 21, -343485551);
    a = ad(a, olda);
    b = ad(b, oldb);
    c = ad(c, oldc);
    d = ad(d, oldd);
  }
  return rh(a) + rh(b) + rh(c) + rh(d);
}

function getStyledExportHeaders(headerArray: string[]) {
  const headerStyle = {
    fill: { fgColor: { rgb: '4E5BA6' } },
    font: { color: { rgb: 'FFFFFF' } },
  };

  return headerArray.map((header) => ({
    v: header,
    t: 't',
    s: headerStyle,
  }));
}

function getStyledRows(rowArray: any) {
  const rowStyle = {
    fill: { fgColor: { rgb: 'DDDDDD' } },
    font: { color: { rgb: '000000' } },
    border: {
      top: { style: 'thin', color: { rgb: 'FFFFFF' } },
      left: { style: 'thin', color: { rgb: 'FFFFFF' } },
      bottom: { style: 'thin', color: { rgb: 'FFFFFF' } },
      right: { style: 'thin', color: { rgb: 'FFFFFF' } },
    },
  };

  return rowArray.map((innerRowArray) => [
    ...innerRowArray.map((innerRow) => ({
      ...innerRow,
      s: { ...innerRow.s, rowStyle },
    })),
  ]);
}

async function parseJsonFile(file) {
  return new Promise((resolve, reject) => {
    try {
      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        try {
          const parsed = JSON.parse(event?.target?.result as string);
          resolve(parsed);
        } catch (e) {
          reject(e);
        }
      };
      fileReader.onerror = (error) => reject(error);
      fileReader.readAsText(file);
    } catch (error) {
      console.log({ error });
      reject(error);
    }
  });
}

function capitaliseString(str: string) {
  const arr = str.split(' ');

  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  return arr.join(' ');
}

function getCleanedRoomScheduleData(roomSchedule: RoomSchedule) {
  if (!roomSchedule) {
    return null;
  }
  return {
    ...roomSchedule,
    data: roomSchedule?.data?.map((room) => {
      return {
        areaFloor: room.areaFloor,

        areaWall: room.areaWall,

        deleted: room.deleted,

        floor: room.floor,

        id: room.id,

        name: room.name,
      };
    }),
  };
}

function getRoomIdFromRoomNameAndFloor(name: string, floor: string) {
  const nameTrimmed = String(name).trim();
  const floorTrimmed = String(floor).trim();
  const roomId = stringToHash(nameTrimmed + floorTrimmed);
  return roomId;
}

function addIdsToItemsIfNeeded(items: any[], helperData?: any) {
  return items?.map((item) => {
    if (item) {
      return { ...item, id: item.id || getRandomID() };
    } else if (helperData) {
      recordError('image was set to null', helperData);
    }
  });
}

function getUserRoleStringId(uid: string, clientId: string) {
  if (!uid || !clientId) {
    throw new Error('You must supply both a uid and clientId');
  }
  return `${uid}_${clientId}`;
}

function getSafeExcelSheetName(name: string) {
  return name.replace(/[:\\/?*[\]]/gi, '_');
}

async function reduceImage(image): Promise<{ blob?: Blob; error?: string }> {
  const MAX_IMAGE_DIMENSION = 2500;
  try {
    const blob = await ImageBlobReduce().toBlob(image, {
      max: MAX_IMAGE_DIMENSION,
    });
    return { blob, error: undefined };
  } catch (err) {
    const errorMessage =
      'There was an error processing an image, please ensure it is a valid image and try again.';
    return { blob: undefined, error: errorMessage };
  }
}

async function convertHeic(heicImage: File): Promise<Blob | Blob[]> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async function (e) {
      if (!e.target?.result) {
        return;
      }
      const blob = new Blob([new Uint8Array(e.target.result as ArrayBuffer)], {
        type: heicImage.type,
      });

      try {
        const converted = await heic2any({
          blob,
          toType: 'image/jpeg',
          quality: 1,
        });
        resolve(converted);
      } catch (error) {
        reject(error);
      }
    };
    reader.readAsArrayBuffer(heicImage);
  });
}

async function compressImage(
  file: File | Blob,
  quality: number = 1,
): Promise<Blob> {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality,

      // The compression process is asynchronous,
      // which means you have to access the `result` in the `success` hook function.
      success(result) {
        resolve(result);
      },
      error(err) {
        console.log(err.message);
      },
    });
  });
}

async function uploadImageIfNeeded({
  image,
  bucketPath,
  uploadPath,
  quality = 1,
}: {
  image: ImageProps;
  bucketPath: string;
  uploadPath: string;
  quality?: number;
}) {
  console.log('upload image if needed: quality', quality);
  try {
    if (!isUrl(image.uri) && !isLocalImage(image.uri)) {
      const storage = getStorageForPath(bucketPath);
      const { width, height } = await getImageDimensions(image.uri);
      const imageUploadRef = storage.ref(`${uploadPath}/${image.id}.jpeg`);

      if (image.uri.indexOf('data:image') === 0) {
        await imageUploadRef.putString(image.uri, 'data_url', {
          contentType: 'image/jpeg',
        });
      } else if (image.uri.indexOf('blob:') === 0) {
        let blob = await getBlobFile(image.uri);
        if (quality < 1) {
          blob = await compressImage(blob, quality);
          console.log('Compressed image', blob);
        }
        await imageUploadRef.put(blob);
      } else {
        alert('Invalid image type');
        throw new Error('NOT A VALID IMAGE URL');
      }

      const imageUrl = await imageUploadRef.getDownloadURL();

      const imageURLShort = await shortenURL(imageUrl);
      const response = {
        uri: imageUrl,
        uriShort: imageURLShort,
        width,
        height,
        id: image.id || getRandomID(),
      };
      return Promise.resolve(response);
    }
    return Promise.resolve(image);
  } catch (e) {
    console.error(e);
  }
}

function parseSamlError(error: Error) {
  const objectStart = error.message.indexOf('{"message');
  const objectEnd = error.message.indexOf('}');
  const message = error.message.substring(objectStart, objectEnd + 1);
  return JSON.parse(message);
}

function splitStringOnUpperCase(str: string) {
  return str.split(/(?=[A-Z])/).join(' ');
}

function getRoomForAssetImport(rooms, name, floor) {
  return rooms.find(
    (r) =>
      String(r.name).trim() === String(name).trim() &&
      String(r.floor).trim() === String(floor).trim(),
  );
}

function getImportsSortedByRoom(rows, rooms) {
  const importsByRoom = {};

  rows?.forEach((row) => {
    try {
      const room = getRoomForAssetImport(
        rooms,
        row['Room Name'] || UNSPECIFIED_LABEL,
        row['Floor'] || UNSPECIFIED_LABEL,
      );
      if (!room) {
        return;
      }
      const id = `${room.floor}${room.name}`;
      importsByRoom[id] = importsByRoom[id] || [];
      importsByRoom[id].push(row);
    } catch (err) {
      recordError(err, {
        row,
        importsByRoom,
      });
    }
  });

  return importsByRoom;
}

function isValidCountryCode(code: string) {
  const match = COUNTRY_LIST.find(
    (c: { countryCode: string }) => c.countryCode === code,
  );

  return !!match;
}

export {
  addIdsToItemsIfNeeded,
  autoSizeWorksheetWidth,
  capitaliseString,
  clientIdRegex,
  companyNumberRegex,
  convertHeic,
  dateFromTimeStamp,
  documentIdMatch,
  emailRegex,
  fileNameAndExtensionFromPath,
  getAllCombinedRoomsForSurvey,
  getBarcodeFormFieldFromSurvey,
  getBlobFile,
  getCleanedRoomScheduleData,
  getColumnFormat,
  getConditionColour,
  getImageDimensions,
  getImportsSortedByRoom,
  getMainImage,
  getPrevUrlSection,
  getRandomHyphenatedStringFromArray,
  getRandomID,
  getRandomNumberBetween,
  getRoomForAssetImport,
  getRoomIdFromRoomNameAndFloor,
  getSafeExcelSheetName,
  getSafeTextFromText,
  getStatusColour,
  getStatusStats,
  getStorageForPath,
  getStyledExportHeaders,
  getStyledRows,
  getTextFromSafeText,
  getUserRoleStringId,
  groupArrayByProp,
  handleLocalImage,
  handlePlural,
  isBlobImage,
  isLocalImage,
  isSameFloorplan,
  isUrl,
  isValidCountryCode,
  isViewingClientPortal,
  isViewingLocalServer,
  isViewingProdServer,
  numberRegex,
  parseJsonFile,
  parseSamlError,
  parseXLSX,
  parseXLSXSheet,
  passwordRegex,
  readURLAsBinary,
  readURLAsData,
  reduceImage,
  refAdapter,
  removeDuplicatesFromArray,
  removeFalsiesFromObject,
  removeNewLineCharacters,
  removeUndefinedFromObject,
  replaceLeadingDigitsWithLetters,
  reportTypeToString,
  shortenURL,
  sortArrayByProp,
  sortTypes,
  splitStringOnUpperCase,
  statuses,
  statusesToPretty,
  statusToString,
  stringToHash,
  timeFromTimeStamp,
  trimWhitespaceFromRows,
  uploadImageIfNeeded,
};
