import { Button, Stack } from '@mui/material';
import { flushSync, render } from 'react-dom';
import { useEffect, useMemo, useRef } from 'react';

import { CreatePhotoScheduleReportData } from '../../../shared/types';
import { PhotoSchedulePDFReport } from '../pdf/PhotoSchedulePDFReport';

interface PreviewStep {
  handleBack: () => void;
  handleNext: () => void;
  data: CreatePhotoScheduleReportData;
}

export default function PreviewStep({
  handleBack,
  handleNext,
  data,
}: PreviewStep) {
  const memoizedData = useMemo(() => {
    return data;
  }, [data.surveyIds.length]);

  const previewRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (previewRef.current) {
      previewRef.current.srcdoc = '';
    }
    if (document.getElementById('pdf-app')) {
      document.getElementById('pdf-app')?.remove();
    }
    const html = previewRef.current?.contentDocument?.createElement('html');
    if (!html) {
      return console.error('Could not create div');
    }
    html.id = 'pdf-app';

    setTimeout(() => {
      flushSync(() => {
        render(<PhotoSchedulePDFReport data={memoizedData} />, html);
      });

      if (previewRef.current) {
        previewRef.current.srcdoc = html.outerHTML;
      }
    });
  }, [memoizedData]);

  return (
    <div>
      <Stack direction={'row'} justifyContent="space-between">
        <Button variant="outlined" onClick={handleBack}>
          Back
        </Button>
        <Button variant={'contained'} onClick={handleNext}>
          Finish
        </Button>
      </Stack>
      <Stack
        py={2}
        spacing={2}
        alignItems="center"
        justifyContent="center"
        direction="column"
        sx={{ width: '100%' }}
      >
        <iframe
          id="pdf-preview"
          ref={previewRef}
          style={{ width: '100%', height: '297mm' }}
        />
      </Stack>
    </div>
  );
}
